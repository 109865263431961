<template>
    <div class="discover-shopping-world">
      <div class="discover-shopping-world_ellipse"></div>
      <div class="discover-shopping-world_title">
        {{ $t("Discover the best shopping world", { ns: 'site' }) }}
      </div>
      <div class="discover-shopping-world_text">
        {{ $t("Buy your favourite games for a low price.", { ns: 'site' }) }}<br />
        {{ $t("Discounts, Prices, Bonuses for Games", { ns: 'site' }) }}
      </div>
  </div>
</template>
