<template>
  <div class="advantages-section">
    <div class="container container-xl">
      <div class="advantages-section__box-container" v-if="!mobile">
        <div class="advantages-section__box left">
          <div class="advantages-section__box-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
            >
              <path
                d="M16.9844 5.84218C16.9844 5.31146 16.9592 4.78092 16.9592 4.30082C16.9592 3.92187 16.656 3.59331 16.2517 3.59331C13.2701 3.59331 10.9706 2.73423 9.07548 0.889517C8.79752 0.636763 8.36797 0.636763 8.09002 0.889517C6.19491 2.73405 3.92085 3.59331 0.913844 3.59331C0.534893 3.59331 0.206333 3.89647 0.206333 4.30082C0.206333 4.78096 0.206334 5.31146 0.181129 5.84218C0.0801331 10.8706 -0.0462435 17.7435 8.3175 20.6494C8.39329 20.6746 8.46908 20.7 8.54487 20.7C8.62066 20.7 8.69646 20.7 8.77225 20.6494C17.1861 17.7434 17.0602 10.8706 16.9844 5.84218H16.9844ZM11.9813 9.2533L8.11523 13.1195C7.98885 13.2459 7.81206 13.3217 7.60991 13.3217C7.43294 13.3217 7.25616 13.2459 7.10459 13.1195L5.18411 11.199C4.90615 10.9211 4.90615 10.4915 5.18411 10.2136C5.46207 9.93562 5.89162 9.93562 6.16957 10.2136L7.60995 11.654L10.9958 8.26807C11.2738 7.99011 11.7034 7.99011 11.9813 8.26807C12.2593 8.54585 12.2593 8.97558 11.9813 9.25335L11.9813 9.2533Z"
                fill="#BEBEBE"
              />
            </svg>
          </div>
          <div class="advantages-section__box-title">
            {{ $t("Buyer Protection", { ns: 'site' }) }}
          </div>
          <div class="advantages-section__box-text">
            {{ $t("Secure transactions and personal data", { ns: 'site' }) }}
          </div>
          <div class="advantages-section__box-ellipse hover"></div>
        </div>
        <div class="advantages-section__box middle">
          <div class="advantages-section__box-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 17.4545C2 18.1296 2.26339 18.7771 2.73223 19.2545C3.20107 19.7318 3.83696 20 4.5 20H19.5C20.163 20 20.7989 19.7318 21.2678 19.2545C21.7366 18.7771 22 18.1296 22 17.4545V10.4545H2V17.4545ZM4.94643 14C4.94643 13.6383 5.08753 13.2915 5.3387 13.0358C5.58986 12.78 5.93051 12.6364 6.28571 12.6364H8.42857C8.78377 12.6364 9.12442 12.78 9.37559 13.0358C9.62675 13.2915 9.76786 13.6383 9.76786 14V14.9091C9.76786 15.2708 9.62675 15.6176 9.37559 15.8733C9.12442 16.1291 8.78377 16.2727 8.42857 16.2727H6.28571C5.93051 16.2727 5.58986 16.1291 5.3387 15.8733C5.08753 15.6176 4.94643 15.2708 4.94643 14.9091V14ZM19.5 4H4.5C3.83696 4 3.20107 4.26818 2.73223 4.74555C2.26339 5.22291 2 5.87036 2 6.54545V7.72727H22V6.54545C22 5.87036 21.7366 5.22291 21.2678 4.74555C20.7989 4.26818 20.163 4 19.5 4Z"
                fill="#BEBEBE"
              />
            </svg>
          </div>
          <div class="advantages-section__box-title">
            {{ $t("Convenient payment", { ns: 'site' }) }}
          </div>
          <div class="advantages-section__box-text">
            {{ $t("300+ payment methods available", { ns: 'site' }) }}
          </div>
          <div class="advantages-section__box-ellipse hover"></div>
        </div>
        <div class="advantages-section__box right">
          <div class="advantages-section__box-img">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.3054 22.1687C18.829 22.7282 18.1964 23 17.3715 23H6.62831C5.80322 23 5.17066 22.7282 4.69436 22.169C4.22708 21.6204 4 20.8714 4 19.8799C4 19.3642 4.01379 18.8551 4.04137 18.3663C4.06826 17.8867 4.12325 17.3599 4.20479 16.8002C4.2853 16.2473 4.38778 15.7284 4.50966 15.2587C4.62661 14.8083 4.78611 14.3623 4.98392 13.9328C5.1727 13.5234 5.3899 13.1721 5.62959 12.8891C5.85377 12.6243 6.13635 12.4076 6.46932 12.2451C6.77725 12.0948 7.12334 12.0124 7.49903 12C7.54481 12.0301 7.62635 12.0874 7.75845 12.1937C8.02724 12.4097 8.33705 12.6562 8.67954 12.926C9.06562 13.2297 9.56302 13.5038 10.1573 13.7405C10.7648 13.9828 11.3845 14.1059 11.9995 14.1059C12.6145 14.1059 13.2343 13.9828 13.8416 13.7406C14.4363 13.5037 14.9335 13.2297 15.3201 12.9256C15.6706 12.6492 15.9718 12.4099 16.2405 12.1937C16.3726 12.0877 16.4542 12.0301 16.4999 12C16.8758 12.0124 17.2219 12.0948 17.53 12.2451C17.8628 12.4076 18.1453 12.6245 18.3696 12.8891C18.6093 13.1719 18.8265 13.5232 19.0153 13.933C19.2132 14.3623 19.3729 14.8086 19.4896 15.2585C19.6117 15.7288 19.7144 16.2475 19.7947 16.7999C19.8761 17.3607 19.9312 17.8878 19.9582 18.3665C19.9858 18.8534 19.9998 19.3628 20 19.8799C19.9998 20.8716 19.7728 21.6204 19.3054 22.1687Z" fill="#BEBEBE"/>
              <path d="M8.46511 3.46474C9.45071 2.4791 10.6069 2 12 2C13.3929 2 14.5494 2.4791 15.535 3.46474C16.5207 4.4506 17 5.60705 17 6.99979C17 8.39295 16.5207 9.54919 15.535 10.535C14.5494 11.5209 13.3929 12 12 12C10.6073 12 9.45112 11.5207 8.46511 10.535C7.47929 9.5494 7 8.39295 7 6.99979C7 5.60705 7.47929 4.4506 8.46511 3.46474Z" fill="#BEBEBE"/>
              </svg>              
          </div>
          <div class="advantages-section__box-title">
            {{ $t("Support 24 / 7") }}
          </div>
          <div class="advantages-section__box-text">
            {{ $t("Qualified support team", { ns: 'site' }) }}
          </div>
          <div class="advantages-section__box-ellipse hover"></div>
        </div>
      </div>
      <Swiper
        v-else
        :slides-per-view="1"
        :loop="true"
        :navigation="{
          prevEl: prev,
          nextEl: next,
        }"
        :modules="modules"
        :pagination="{
          clickable: true,
        }"
        class="advantages-swiper"
      >
        <SwiperSlide>
          <div class="advantages-section__box left">
            <div class="advantages-section__box-img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="21"
                viewBox="0 0 17 21"
                fill="none"
              >
                <path
                  d="M16.9844 5.84218C16.9844 5.31146 16.9592 4.78092 16.9592 4.30082C16.9592 3.92187 16.656 3.59331 16.2517 3.59331C13.2701 3.59331 10.9706 2.73423 9.07548 0.889517C8.79752 0.636763 8.36797 0.636763 8.09002 0.889517C6.19491 2.73405 3.92085 3.59331 0.913844 3.59331C0.534893 3.59331 0.206333 3.89647 0.206333 4.30082C0.206333 4.78096 0.206334 5.31146 0.181129 5.84218C0.0801331 10.8706 -0.0462435 17.7435 8.3175 20.6494C8.39329 20.6746 8.46908 20.7 8.54487 20.7C8.62066 20.7 8.69646 20.7 8.77225 20.6494C17.1861 17.7434 17.0602 10.8706 16.9844 5.84218H16.9844ZM11.9813 9.2533L8.11523 13.1195C7.98885 13.2459 7.81206 13.3217 7.60991 13.3217C7.43294 13.3217 7.25616 13.2459 7.10459 13.1195L5.18411 11.199C4.90615 10.9211 4.90615 10.4915 5.18411 10.2136C5.46207 9.93562 5.89162 9.93562 6.16957 10.2136L7.60995 11.654L10.9958 8.26807C11.2738 7.99011 11.7034 7.99011 11.9813 8.26807C12.2593 8.54585 12.2593 8.97558 11.9813 9.25335L11.9813 9.2533Z"
                  fill="#BEBEBE"
                />
              </svg>
            </div>
            <div class="advantages-section__box-title">
              {{ $t("Buyer Protection", { ns: 'site' }) }}
            </div>
            <div class="advantages-section__box-text">
              {{ $t("Secure transactions and personal data", { ns: 'site' }) }}
            </div>
            <div class="advantages-section__box-ellipse hover"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="advantages-section__box middle">
            <div class="advantages-section__box-img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 17.4545C2 18.1296 2.26339 18.7771 2.73223 19.2545C3.20107 19.7318 3.83696 20 4.5 20H19.5C20.163 20 20.7989 19.7318 21.2678 19.2545C21.7366 18.7771 22 18.1296 22 17.4545V10.4545H2V17.4545ZM4.94643 14C4.94643 13.6383 5.08753 13.2915 5.3387 13.0358C5.58986 12.78 5.93051 12.6364 6.28571 12.6364H8.42857C8.78377 12.6364 9.12442 12.78 9.37559 13.0358C9.62675 13.2915 9.76786 13.6383 9.76786 14V14.9091C9.76786 15.2708 9.62675 15.6176 9.37559 15.8733C9.12442 16.1291 8.78377 16.2727 8.42857 16.2727H6.28571C5.93051 16.2727 5.58986 16.1291 5.3387 15.8733C5.08753 15.6176 4.94643 15.2708 4.94643 14.9091V14ZM19.5 4H4.5C3.83696 4 3.20107 4.26818 2.73223 4.74555C2.26339 5.22291 2 5.87036 2 6.54545V7.72727H22V6.54545C22 5.87036 21.7366 5.22291 21.2678 4.74555C20.7989 4.26818 20.163 4 19.5 4Z"
                  fill="#BEBEBE"
                />
              </svg>
            </div>
            <div class="advantages-section__box-title">
              {{ $t("Convenient payment", { ns: 'site' }) }}
            </div>
            <div class="advantages-section__box-text">
              {{ $t("300+ payment methods available", { ns: 'site' }) }}
            </div>
            <div class="advantages-section__box-ellipse hover"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="advantages-section__box right">
            <div class="advantages-section__box-img">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3054 22.1687C18.829 22.7282 18.1964 23 17.3715 23H6.62831C5.80322 23 5.17066 22.7282 4.69436 22.169C4.22708 21.6204 4 20.8714 4 19.8799C4 19.3642 4.01379 18.8551 4.04137 18.3663C4.06826 17.8867 4.12325 17.3599 4.20479 16.8002C4.2853 16.2473 4.38778 15.7284 4.50966 15.2587C4.62661 14.8083 4.78611 14.3623 4.98392 13.9328C5.1727 13.5234 5.3899 13.1721 5.62959 12.8891C5.85377 12.6243 6.13635 12.4076 6.46932 12.2451C6.77725 12.0948 7.12334 12.0124 7.49903 12C7.54481 12.0301 7.62635 12.0874 7.75845 12.1937C8.02724 12.4097 8.33705 12.6562 8.67954 12.926C9.06562 13.2297 9.56302 13.5038 10.1573 13.7405C10.7648 13.9828 11.3845 14.1059 11.9995 14.1059C12.6145 14.1059 13.2343 13.9828 13.8416 13.7406C14.4363 13.5037 14.9335 13.2297 15.3201 12.9256C15.6706 12.6492 15.9718 12.4099 16.2405 12.1937C16.3726 12.0877 16.4542 12.0301 16.4999 12C16.8758 12.0124 17.2219 12.0948 17.53 12.2451C17.8628 12.4076 18.1453 12.6245 18.3696 12.8891C18.6093 13.1719 18.8265 13.5232 19.0153 13.933C19.2132 14.3623 19.3729 14.8086 19.4896 15.2585C19.6117 15.7288 19.7144 16.2475 19.7947 16.7999C19.8761 17.3607 19.9312 17.8878 19.9582 18.3665C19.9858 18.8534 19.9998 19.3628 20 19.8799C19.9998 20.8716 19.7728 21.6204 19.3054 22.1687Z" fill="#BEBEBE"/>
                <path d="M8.46511 3.46474C9.45071 2.4791 10.6069 2 12 2C13.3929 2 14.5494 2.4791 15.535 3.46474C16.5207 4.4506 17 5.60705 17 6.99979C17 8.39295 16.5207 9.54919 15.535 10.535C14.5494 11.5209 13.3929 12 12 12C10.6073 12 9.45112 11.5207 8.46511 10.535C7.47929 9.5494 7 8.39295 7 6.99979C7 5.60705 7.47929 4.4506 8.46511 3.46474Z" fill="#BEBEBE"/>
              </svg>                
            </div>
            <div class="advantages-section__box-title">
              {{ $t("Support 24 / 7") }}
            </div>
            <div class="advantages-section__box-text">
              {{ $t("Qualified support team", { ns: 'site' }) }}
            </div>
            <div class="advantages-section__box-ellipse hover"></div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div ref="prev" class="swiper-button-prev" v-if="mobile">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M8.01959 12.3098C8.01959 12.0589 8.11539 11.808 8.30659 11.6167L14.3264 5.59701C14.7093 5.21408 15.3301 5.21408 15.7129 5.59701C16.0957 5.97979 16.0957 6.60052 15.7129 6.98349L10.3863 12.3098L15.7127 17.6361C16.0955 18.0191 16.0955 18.6397 15.7127 19.0225C15.33 19.4056 14.7091 19.4056 14.3262 19.0225L8.30641 13.0029C8.11517 12.8115 8.01959 12.5606 8.01959 12.3098Z" fill="#F6F6F6"/>
        </svg>
      </div>
      <div ref="next" class="swiper-button-next" v-if="mobile">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15.9804 11.6902C15.9804 11.9411 15.8846 12.192 15.6934 12.3833L9.67365 18.403C9.29072 18.7859 8.66986 18.7859 8.28708 18.403C7.90431 18.0202 7.90431 17.3995 8.28708 17.0165L13.6137 11.6902L8.28727 6.36387C7.90449 5.98094 7.90449 5.36027 8.28727 4.97752C8.67004 4.59441 9.2909 4.59441 9.67383 4.97752L15.6936 10.9971C15.8848 11.1885 15.9804 11.4394 15.9804 11.6902Z" fill="#F6F6F6"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
};
</script>
