export const LazyLoadDirective = {
    mounted(el, binding) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (typeof binding.value === 'function') {
              binding.value(); // Call the function passed to the directive
              observer.unobserve(el);
            } else {
              console.error('LazyLoadDirective: Binding value is not a function.');
            }
          }
        });
      }, { threshold: 0.1 });
  
      observer.observe(el);
    },
  };