export function translate(keyOrParams, messages, ...params) {
    let namespace = 'common';
    let key = keyOrParams;

    if (typeof keyOrParams === 'object' && !Array.isArray(keyOrParams)) {
        const { ns, ...otherParams } = keyOrParams;
        params = [otherParams];
        namespace = ns || 'common';
    } else if (typeof keyOrParams === 'string') {
        key = keyOrParams;
    }

    let translation = messages[namespace] && messages[namespace][key] ? messages[namespace][key] : null;

    if (!translation) {
        for (const ns in messages) {
            if (messages[ns][key]) {
                translation = messages[ns][key];
                break;
            }
        }
    }

    if (!translation) {
        translation = key;
    }

    if (params.length === 1 && typeof params[0] === 'string') {
        params = { 0: params[0] };
    } else if (params.length === 1 && typeof params[0] === 'object') {
        params = params[0];
    }

    Object.keys(params).forEach(param => {
        const regex = new RegExp(`{${param}}`, 'g');
        translation = translation.replace(regex, params[param]);
    });

    return translation;
}

export function loadMessages(language, controllers) {
    const messages = {};
    
    try {
        messages['common'] = require(`./../messages/${language}/app.json`);
    } catch (e) {
        console.error(`Error loading messages for common in ${language}:`, e);
    }

    controllers.forEach(controller => {
        try {
            messages[controller] = require(`./../apps/${controller}/messages/${language}/app.json`);
        } catch (e) {
            console.error(`Error loading messages for ${controller} in ${language}:`, e);
        }
    });

    return { languages: [language], messages };
}