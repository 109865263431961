<template>
  <div>
    <Banners :mobile="mobile" :settings="settings"/>
    <Products :keyType="POPULAR_GAMES" :tablet="tablet" :settings="settings"/>
    <EasyAndQuick :settings="settings" :mobile="mobile" v-if="isKeysForGamers"/>
    <Advantages :mobile="mobile" v-else/>
    <Products :keyType="PRE_ORDER" :tablet="tablet" :settings="settings"/>
    <CategoryKfg :mobile="mobile" :settings="settings" v-if="isKeysForGamers"/>
    <Category :mobile="mobile" :settings="settings" v-else/>
    <Sellers :settings="settings"/>
    <CategoryKfg2 :settings="settings" v-if="isKeysForGamers"/>
    <Category2 :settings="settings" v-if="!mobile && !isKeysForGamers"/>
    <Offers :keyType="NEW_ON_SITE" :tablet="tablet" :settings="settings"/>
    <DiscoverKfg :mobile="mobile" :settings="settings" v-if="isKeysForGamers"/>
    <Discover v-else />
    <Offers :keyType="WEEKLY_CHART" :tablet="tablet" :settings="settings"/>
    <BestDealsKfg :settings="settings" v-if="isKeysForGamers"/>
    <BestDeals :settings="settings" v-else/>
    <Offers :keyType="UNDER_TEN" :tablet="tablet" :settings="settings"/>
    <PlatformsKfg :mobile="mobile" :settings="settings" v-if="isKeysForGamers"/>
    <Platforms :mobile="mobile" :settings="settings" v-else/>
    <News :settings="settings"/>
  </div>
</template>

<script>
import {POPULAR_GAMES, PRE_ORDER} from '../../const/site/index/products';
import {NEW_ON_SITE, WEEKLY_CHART, UNDER_TEN} from '../../const/site/index/offers';

import Banners from './index/Banners.vue';
import Products from './index/Products.vue';
import Advantages from './index/Advantages.vue';
import Discover from './index/Discover.vue';
import DiscoverKfg from './index/Discover-kfg.vue';
import Offers from './index/Offers.vue';
import Sellers from './index/Sellers.vue';
import Platforms from './index/Platforms.vue';
import PlatformsKfg from './index/Platforms-kfg.vue';
import News from './index/News.vue';
import Category from './index/Category.vue';
import CategoryKfg2 from './index/Category-kfg2.vue';
import CategoryKfg from './index/Category-kfg.vue';
import Category2 from './index/Category2.vue';
import BestDeals from './index/BestDeals.vue';
import BestDealsKfg from './index/BestDeals-kfg.vue';
import EasyAndQuick from './index/EasyAndQuick.vue';

export default {
  components: {
    Banners,
    Products,
    Advantages,
    Discover,
    DiscoverKfg,
    BestDeals,
    BestDealsKfg,
    Offers,
    Sellers,
    Platforms,
    PlatformsKfg,
    Category,
    Category2,
    CategoryKfg,
    CategoryKfg2,
    EasyAndQuick,
    News,
  },
  data() {
    return {
      settings: window.settings,
      mobile: false,
      tablet: false,
      POPULAR_GAMES,
      PRE_ORDER,
      NEW_ON_SITE,
      WEEKLY_CHART,
      UNDER_TEN,
      isKeysForGamers: window.settings.company_name === 'Keysforgamers'
    };
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    this.checkTablet();
    window.addEventListener('resize', this.checkTablet);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
    window.removeEventListener('resize', this.checkTablet);
  },
  methods: {
    checkMobile() {
      this.mobile = window.innerWidth < 768;
    },
    checkTablet() {
      this.tablet = window.innerWidth < 1024;
    }
  }
};
</script>

<style>

[v-cloak] {
  display: none;
}
</style>