<template>
  <div class="news-block-wrap" :class="{ 'keysforgamers': settings.company_name == 'Keysforgamers' }" v-lazy-load="fetchNews">
    <div class="container container-xl">
      <div class="card-title-box">
        <div class="title"><h3>{{ $t('Latest News', { ns: 'site' }) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/news'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="row placeholder-list" :class="{ 'opacity': showPlaceholderOpacity }" v-if="showPlaceholder">
        <div v-for="index in 4" :key="index" class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
          <div class="catalog-card placeholder-block">
            <div class="placeholder-block__img placeholder"></div>
            <div class="placeholder-block__time">
              <div class="icon">
                <div class="placeholder"></div>
              </div>
              <div class="text">
                <div class="placeholder"></div>
              </div>
            </div>
            <div class="placeholder-block__title placeholder"></div>
            <div class="placeholder-block__title w-50 placeholder"></div>
            <div class="placeholder-block__description placeholder"></div>
            <div class="placeholder-block__description w-90 placeholder"></div>
            <div class="placeholder-block__description w-80 placeholder"></div>
            <div class="placeholder-block__description w-70 placeholder"></div>
            <div class="placeholder-block__bottom">
              <div class="icon">
                <div class="placeholder"></div>
              </div>
              <div class="text">
                <div class="placeholder"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <Swiper
            v-if="showSlider"
            :slides-per-view="'auto'"
            :centeredSlides="true"
            :spaceBetween="0"
            :initialSlide="1"
            :loop="true"
            noSwiping
            noSwipingSelector=""
            :breakpoints="{
            '767': {
              slidesPerView: 2,
              centeredSlides: false,
              initialSlide: 0,
              loop: true,
            },
            '1024': {
              slidesPerView: 3,
              centeredSlides: false,
              noSwipingSelector: '.swiper-slide',
              initialSlide: 0,
              loop: false,
            },
            '1920': {
              slidesPerView: 4,
              centeredSlides: false,
              noSwipingSelector: '.swiper-slide',
              initialSlide: 0,
              loop: false,
            },
          }"
        >
          <SwiperSlide v-for="(newsItem, index) in news" :key="index">
            <div class="col-xs-12">
              <a :href="newsItem.url" class="news-item" rel="preload" >
                <img :src="newsItem.image" alt="News image" rel="preload" as="image">
                <div class="wrap-info">
                  <div class="wrap-info__time">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                          d="M7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252ZM7.00016 1.26325C5.4787 1.26325 4.01956 1.86765 2.94373 2.94348C1.86789 4.01932 1.2635 5.47846 1.2635 6.99992C1.2635 8.52138 1.86789 9.98052 2.94373 11.0564C4.01956 12.1322 5.4787 12.7366 7.00016 12.7366C8.52162 12.7366 9.98077 12.1322 11.0566 11.0564C12.1324 9.98052 12.7368 8.52138 12.7368 6.99992C12.7368 5.47846 12.1324 4.01932 11.0566 2.94348C9.98077 1.86765 8.52162 1.26325 7.00016 1.26325ZM6.38016 4.05392C6.63683 4.05392 6.84483 4.26259 6.84483 4.51925V7.77525H10.1008C10.2211 7.77981 10.3349 7.83079 10.4184 7.9175C10.5019 8.0042 10.5486 8.11989 10.5486 8.24025C10.5486 8.36062 10.5019 8.4763 10.4184 8.56301C10.3349 8.64971 10.2211 8.7007 10.1008 8.70525H6.38016C6.25675 8.70525 6.13839 8.65623 6.05112 8.56896C5.96386 8.48169 5.91483 8.36333 5.91483 8.23992V4.51992C5.91483 4.26259 6.12283 4.05392 6.38016 4.05392Z"
                          fill="#9BA1AB"></path>
                    </svg>
                    {{ newsItem.time }}
                  </div>
                  <div class="wrap-info__title">
                    {{ newsItem.name }}
                  </div>
                  <div class="wrap-info__info">
                    {{ newsItem.description }}
                  </div>
                  <div class="wrap-info__bottom">
                    <div class="views">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M23.2052 11.745C22.3231 9.46324 20.7917 7.48996 18.8003 6.06906C16.8089 4.64817 14.4448 3.84193 12.0002 3.75C9.55556 3.84193 7.19146 4.64817 5.20007 6.06906C3.20868 7.48996 1.67729 9.46324 0.795169 11.745C0.735595 11.9098 0.735595 12.0902 0.795169 12.255C1.67729 14.5368 3.20868 16.51 5.20007 17.9309C7.19146 19.3518 9.55556 20.1581 12.0002 20.25C14.4448 20.1581 16.8089 19.3518 18.8003 17.9309C20.7917 16.51 22.3231 14.5368 23.2052 12.255C23.2647 12.0902 23.2647 11.9098 23.2052 11.745ZM12.0002 18.75C8.02517 18.75 3.82517 15.8025 2.30267 12C3.82517 8.1975 8.02517 5.25 12.0002 5.25C15.9752 5.25 20.1752 8.1975 21.6977 12C20.1752 15.8025 15.9752 18.75 12.0002 18.75Z"
                            fill="#9BA1AB"></path>
                        <path
                            d="M12 7.5C11.11 7.5 10.24 7.76392 9.49994 8.25839C8.75991 8.75285 8.18314 9.45566 7.84254 10.2779C7.50195 11.1002 7.41283 12.005 7.58647 12.8779C7.7601 13.7508 8.18869 14.5526 8.81802 15.182C9.44736 15.8113 10.2492 16.2399 11.1221 16.4135C11.995 16.5872 12.8998 16.4981 13.7221 16.1575C14.5443 15.8169 15.2471 15.2401 15.7416 14.5001C16.2361 13.76 16.5 12.89 16.5 12C16.5 10.8065 16.0259 9.66193 15.182 8.81802C14.3381 7.97411 13.1935 7.5 12 7.5ZM12 15C11.4067 15 10.8266 14.8241 10.3333 14.4944C9.83994 14.1648 9.45543 13.6962 9.22836 13.148C9.0013 12.5999 8.94189 11.9967 9.05765 11.4147C9.1734 10.8328 9.45912 10.2982 9.87868 9.87868C10.2982 9.45912 10.8328 9.1734 11.4147 9.05764C11.9967 8.94189 12.5999 9.0013 13.1481 9.22836C13.6962 9.45542 14.1648 9.83994 14.4944 10.3333C14.8241 10.8266 15 11.4067 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7957 15 12 15Z"
                            fill="#9BA1AB"></path>
                      </svg>
                      <span>{{ newsItem.view_count === 0 ? 1 : newsItem.view_count }}</span>
                    </div>
                    <!-- <div class="news-link"></div> -->
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import {LazyLoadDirective} from '../../../directives/LazyLoadDirective';
import {NEW_ON_SITE, WEEKLY_CHART, UNDER_TEN} from '../../../const/site/index/offers';
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  directives: {
    lazyLoad: LazyLoadDirective
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showPlaceholder: true,
      showPlaceholderOpacity: false,
      showSlider: false,
      news: [
        {
          'image': '/img/default/product_new.png',
          'name': ' ',
          'description': ' '
        }
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    handleButtonClick(event) {
      const target = event.currentTarget;
      target.classList.add('preload');
      setTimeout(() => {
        target.classList.remove('preload');
      }, 2500);
    },
    fetchNews() {
      axios.get('/' + this.settings.language + '/site-api/news')
          .then(response => {
            this.news = response.data;
            this.showPlaceholderOpacity = true;
            this.showSlider = true;
            setTimeout(() => {
              this.showPlaceholder = false;
            }, 2000)
          })
          .catch(error => {
            console.error('Error response:', error);
          });
    }
  }
};
</script>