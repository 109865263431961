<template>
  <i :class="['ico', 'user-verify', { 'personal': verifyStatus === false }]">
    <div :class="['step-info', 'step-info-prof', { 'personal': verifyStatus === false }]">
      <span v-if="verifyStatus === true" class="business"></span>
      <span v-else class="personal"></span>
      <p class="step-info-msg">
        {{ getVerificationMessage(verifyStatus) }}
      </p>
    </div>
  </i>
</template>

<script>
export default {
  props: {
    verifyStatus: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {
    getVerificationMessage(status) {
      return status === false
          ? this.$t("The seller's personal account has been verified")
          : this.$t("The seller's business account has been verified");
    },
  },
};
</script>
  