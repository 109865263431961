<template>
    <div class="EasyAndQuick-block">
        <a :href="`/${settings.language}/buy-game-currency`">
            <div class="container container-xl">
                <div class="row">
                    <div class="col-sm-6 mobile-none">
                        <div class="EasyAndQuick-block__animation">
                            <div class="EasyAndQuick-block__animation-el top">
                                <a class="element" :href="`/${settings.language}/products?name=guild+wars+2`">
                                    <img src="/img/pages/site/guild.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=tera`">
                                    <img src="/img/pages/site/tera.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=world+of+warcraft`">
                                    <img src="/img/pages/site/WoW.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=albion`">
                                    <img src="/img/pages/site/albion.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Path+of+Exile`">
                                    <img src="/img/pages/site/exile.webp" alt="">
                                </a>
                            </div>
                            <div class="EasyAndQuick-block__animation-el bottom">
                                <a class="element" :href="`/${settings.language}/products?name=Black+Desert`">
                                    <img src="/img/pages/site/BlackDesert.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=RuneScape`">
                                    <img src="/img/pages/site/RuneScape.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=new+world`">
                                    <img src="/img/pages/site/NewWorld.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Perfect+World`">
                                    <img src="/img/pages/site/PerfectWorld.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Eve+Online`">
                                    <img src="/img/pages/site/eve.webp" alt="">
                                </a>
                            </div>
                            <div class="EasyAndQuick-block__animation-el top last">
                                <a class="element" :href="`/${settings.language}/products?name=ArcheAge`">
                                    <img src="/img/pages/site/archeAge.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Star+Wars`">
                                    <img src="/img/pages/site/StarWars.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Final+Fantasy`">
                                    <img src="/img/pages/site/FinalFantasy.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Warframe`">
                                    <img src="/img/pages/site/warframe.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Lineage+2`">
                                    <img src="/img/pages/site/lineage.webp" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="EasyAndQuick-block__text">
                            <h2>{{ $t("Easy and quick buy currency", { ns: 'site' }) }}</h2>
                            <p>{{ $t("Buy your game currency for your favourite mmorpg and take all of your game expirience.", { ns: 'site' }) }}</p>
                        </div>
                        <div class="EasyAndQuick-block__img-mobile">
                            <div class="EasyAndQuick-block__img-mobile-el left">
                                <a class="element" :href="`/${settings.language}/products?name=ArcheAge`">
                                    <img src="/img/pages/site/archeAge.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Star+Wars`">
                                    <img src="/img/pages/site/StarWars.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Final+Fantasy`">
                                    <img src="/img/pages/site/FinalFantasy.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Warframe`">
                                    <img src="/img/pages/site/warframe.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Lineage+2`">
                                    <img src="/img/pages/site/lineage.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Perfect+World`">
                                    <img src="/img/pages/site/PerfectWorld.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Eve+Online`">
                                    <img src="/img/pages/site/eve.webp" alt="">
                                </a>
                            </div>
                            <div class="EasyAndQuick-block__img-mobile-el right">
                                <a class="element" :href="`/${settings.language}/products?name=guild+wars+2`">
                                    <img src="/img/pages/site/guild.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=tera`">
                                    <img src="/img/pages/site/tera.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=world+of+warcraft`">
                                    <img src="/img/pages/site/WoW.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=albion`">
                                    <img src="/img/pages/site/albion.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Path+of+Exile`">
                                    <img src="/img/pages/site/exile.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=Black+Desert`">
                                    <img src="/img/pages/site/BlackDesert.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=RuneScape`">
                                    <img src="/img/pages/site/RuneScape.webp" alt="">
                                </a>
                                <a class="element" :href="`/${settings.language}/products?name=new+world`">
                                    <img src="/img/pages/site/NewWorld.webp" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
  </div>
</template>
<script>

export default {
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style>
@import "../style/EasyAndQuick.scss";
</style>