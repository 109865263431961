<template>
    <div class="categoty-section-kfg">
        <a :href="settings.urls.top_up" rel="preload" class="categoty-section-kfg__block block-3">
            <div class="categoty-section-kfg__block-img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero-kfg-3.webp" alt="" />
            </div>
            <div class="categoty-section-kfg__block-text">
                <h3>{{ $t("Gift Cards") }}</h3>
                <p>{{ $t("You can also find a gift card to top up your favorite service.", { ns: 'site' }) }}</p>
            </div>
        </a>
        <a :href="settings.urls.software" rel="preload" class="categoty-section-kfg__block block-4">
            <div class="categoty-section-kfg__block-text">
                <h3>{{ $t("Software") }}</h3>
                <p>{{ $t("You can also find software and operation systems at favorable prices.", { ns: 'site' }) }}</p>
            </div>
            <div class="categoty-section-kfg__block-img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero-kfg-4.webp" alt="" />
            </div>
        </a>
    </div>
  </template>
  <script>
  export default {
  props: {
      settings: {
      type: Object,
      default: null,
      },
  },

  };
</script>