<template>
  <div class="product-type-section" :class="{ 'keysforgamers': settings.company_name == 'Keysforgamers' }" v-lazy-load="fetchProducts">
    <div class="container container-xl">
      <div v-if="keyType === POPULAR_GAMES" class="card-title-box">
        <div class="title"><h3>{{ $t('Popular games', { ns: 'site' }) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/products/order/popular'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div v-else-if="keyType === PRE_ORDER" class="card-title-box">
        <div class="title"><h3>{{ $t('Upcoming games', { ns: 'site' }) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/products/type/pre-order'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="row placeholder-list" :class="{ 'opacity': showPlaceholderOpacity }" v-if="showPlaceholder">
        <div v-for="index in 8" :key="index" class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
          <div class="catalog-card placeholder-block ">
            <div class="placeholder-block__img placeholder"></div>
            <div class="placeholder-block__info">
              <div class="platforms">
                <div v-for="platformIndex in 4" :key="platformIndex" class="platform-placeholder placeholder"></div>
              </div>
              <div class="placeholder-block__price placeholder"></div>
            </div>
            <div class="placeholder-block__title">
              <div class="placeholder"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!tablet">
        <div class="col-xs-12 col-md-4 col-xl-3" v-for="(product, index) in products" :key="index">
          <div class="catalog-card catalog-card__wrap catalog-card__wrap--ajax-load card js-video-container"
               @mouseenter="playVideo(product.id)" @mouseleave="pauseVideo(product.id)" :id="product.id">
            <ProductLabel 
              v-if="product.label.text" 
              :label="product.label"
              :keyType="keyType"
            />
            <div class="catalog-card__item-flex-wrap">
              <div class="catalog-card__img-wrap-container">
                <div class="catalog-card__img-wrap">
                  <div v-if="!settings.is_guest"
                       class="bookmark-btn bookmark-btn__container catalog-card__bookmark-btn">
                    <div class="bookmark-btn__wrap bookmark-btn__wrap--bookmark">
                      <a @click="toggleBookmark(product.id)" rel="preload" 
                         class="bookmark-btn__btn-link bookmark-toggle-vue">
                        <div :class="{ 'active': product.is_bookmark }"
                             class="bookmark-btn__btn button-favorite">
                          <div class="bookmark-btn__btn-img">
                            <img rel="preload" as="image" class="nohov" src="/img/to-bookmarks-btn/bookmark-star.svg" alt="">
                            <img rel="preload" as="image" class="hov" src="/img/to-bookmarks-btn/bookmark-star-hover.svg" alt="">
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="catalog-card__img-wrap-static">
                    <a class="catalog-card__img-link" :href="product.url" rel="preload" >
                      <img :src="product.image"
                           alt="Product image"
                           rel="preload" as="image"
                      >
                    </a>
                  </div>
                  <div class="catalog-card__img-wrap-animate" :class="{'play': playVideoClass[product.id] }">
                    <a class="catalog-card__img-link" :href="product.url" rel="preload" >
                      <div class="catalog-card__video-container" :data-video="product.video" v-if="isHover[product.id]">
                        <video :ref="`videoPlayer-${product.id}`" class="js-video-player-vue" loop muted preload="none">
                          <source type="video/mp4" src="">
                        </video>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="catalog-card__product-wrap">
                  <div class="catalog-card__platform-wrap">
                    <a v-for="(platform) in product.platforms" :key="(platform.platform_id || platform.id)"
                       rel="preload"
                       :href="platform.url" class="platform"
                       :class="'platform platform-' + (platform.platform_id || platform.id)"></a>
                  </div>
                  <p class="catalog-card__info">
                    {{ product.price }}
                  </p>
                </div>
              </div>
              <div class="catalog-card__animate-block">
                <div class="catalog-card__info-wrap">
                  <a class="catalog-card__card-link" :href="product.url" rel="preload" >{{ product.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <Swiper
            v-if="showSlider"
            :slides-per-view="'auto'"
            :centeredSlides="true"
            :no-swiping="true"
            :initialSlide="1"
            :loop="true"
            :breakpoints="{
              '767': {
                slidesPerView: 2,
                loop: true,
                centeredSlides: false,
                initialSlide: 0,
              },
            }"
        >
          <SwiperSlide v-for="(product, index) in products" :key="index">
            <div class="col-xs-12 slider_item">
              <div class="catalog-card catalog-card__wrap catalog-card__wrap--ajax-load card js-video-container" :id="product.id">
                <ProductLabel 
                  v-if="product.label.text" 
                  :label="product.label"
                  :keyType="keyType"
                />
                <div class="catalog-card__item-flex-wrap">
                  <div class="catalog-card__img-wrap-container">
                    <div class="catalog-card__img-wrap">
                      <div v-if="!settings.is_guest"
                           class="bookmark-btn bookmark-btn__container catalog-card__bookmark-btn">
                        <div class="bookmark-btn__wrap bookmark-btn__wrap--bookmark">
                          <a @click="toggleBookmark(product.id)" rel="preload" 
                             class="bookmark-btn__btn-link bookmark-toggle-vue">
                            <div :class="{ 'active': product.is_bookmark }"
                                 class="bookmark-btn__btn button-favorite">
                              <div class="bookmark-btn__btn-img">
                                <img rel="preload" as="image" class="nohov" src="/img/to-bookmarks-btn/bookmark-star.svg" alt="">
                                <img rel="preload" as="image" class="hov" src="/img/to-bookmarks-btn/bookmark-star-hover.svg" alt="">
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="catalog-card__img-wrap-static">
                        <a class="catalog-card__img-link" :href="product.url" rel="preload" >
                          <img :src="product.image"
                               alt="Product image"
                               rel="preload" as="image"
                          >
                        </a>
                      </div>
                    </div>
                    <div class="catalog-card__product-wrap">
                      <div class="catalog-card__platform-wrap">
                        <a v-for="(platform) in product.platforms" :key="(platform.platform_id || platform.id)"
                           rel="preload"
                           :href="platform.url" class="platform"
                           :class="'platform platform-' + (platform.platform_id || platform.id)"></a>
                      </div>
                      <p class="catalog-card__info">
                        {{ product.price }}
                      </p>
                    </div>
                  </div>
                  <div class="catalog-card__animate-block">
                    <div class="catalog-card__info-wrap">
                      <a class="catalog-card__card-link" :href="product.url" rel="preload" >{{ product.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import {LazyLoadDirective} from '../../../directives/LazyLoadDirective';
import {POPULAR_GAMES, PRE_ORDER} from '../../../const/site/index/products';

import ProductLabel from '../../components/ProductLabel.vue';

import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';

export default {
  directives: {
    lazyLoad: LazyLoadDirective
  },
  props: {
    keyType: String,
    tablet: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: () => ({
        label: {
          text: '',
          class: '',
          category_id: ''
        }
      })
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ProductLabel
  },
  data() {
    return {
      showPlaceholder: true,
      showPlaceholderOpacity: false,
      playVideoClass: {},
      showSlider: false,
      products: [],
      isHover: {},
      POPULAR_GAMES,
      PRE_ORDER    
    };
  },
  methods: {
    handleButtonClick(event) {
      const target = event.currentTarget;
      target.classList.add('preload');
      setTimeout(() => {
        target.classList.remove('preload');
      }, 2500);
    },
    playVideo(id) {
      this.isHover[id] = true;

      this.$nextTick(() => {
        const videoPlayer = this.$refs[`videoPlayer-${id}`];
        if (!videoPlayer || !videoPlayer[0]) {
          console.error(`Video player with id ${id} not found or is not properly initialized.`);
          return;
        }

        const videoSource = videoPlayer[0].closest('.catalog-card__video-container').getAttribute('data-video');

        if (this.isHover[id]) {
          Object.keys(this.playVideoClass).forEach(key => {
            this.playVideoClass[key] = false;
          });

          if (videoSource) {
            this.playVideoClass[id] = true;
            const sourceElement = videoPlayer[0].querySelector('source');
            sourceElement.setAttribute('src', videoSource);
            videoPlayer[0].load();

            videoPlayer[0].addEventListener('loadeddata', () => {
              videoPlayer[0].play().then(() => {
                this.playVideoClass[id] = true;
              }).catch(error => {
                console.error('Error playing the video:', error);
              });
            }, { once: true });
          }
        }
      });
    },
    pauseVideo(id) {
      Object.keys(this.playVideoClass).forEach(key => {
        this.playVideoClass[key] = false;
      });

      this.$nextTick(() => {
        const videoPlayer = this.$refs[`videoPlayer-${id}`];
        if (!videoPlayer || !videoPlayer[0]) {
          console.error(`Video player with id ${id} not found or is not properly initialized.`);
          return;
        }

        const sourceElement = videoPlayer[0].querySelector('source');
        this.playVideoClass[id] = false;

        if (sourceElement) {
          sourceElement.setAttribute('src', '');
        }

        if (typeof videoPlayer[0].pause === 'function') {
          videoPlayer[0].pause();
        }
      });
      this.isHover[id] = false;
    },
    toggleBookmark(productId) {
      const index = this.products.findIndex(product => product.id === productId);
      this.products[index] = {...this.products[index], is_bookmark: !this.products[index].is_bookmark};
      axios.get(`/` + this.settings.language + `/bookmark/toggle?product_id=${productId}`)
          .catch(error => {
            this.products[index] = {...this.products[index], is_bookmark: false};
            console.error('Error toggling bookmark:', error);
          })
    },
    fetchProducts() {
      axios.get('/' +this.settings.language + '/site-api/products?type=' + this.keyType)
          .then(response => {
            this.products = response.data;
            this.showPlaceholderOpacity = true;
            this.showSlider = true;
            setTimeout(() => {
              this.showPlaceholder = false;
            }, 1000)
          })
          .catch(error => {
            console.error('Error response:', error);
          });
    }
  }
};
</script>
