<template>

	<div class="product__label" v-if="label.class === 'pre-order' && keyType !== 'pre_order'">
		<div class="product__label-item border-right g-6">
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.77461 5.49882C9.5471 4.98677 9.21638 4.52719 8.80313 4.14881L8.46211 3.83592C8.45053 3.82559 8.43659 3.81826 8.42152 3.81457C8.40645 3.81088 8.3907 3.81095 8.37566 3.81477C8.36062 3.81858 8.34674 3.82604 8.33525 3.83647C8.32377 3.8469 8.31501 3.85999 8.30977 3.8746L8.15742 4.31171C8.0625 4.58592 7.88789 4.866 7.64062 5.14139C7.62422 5.15897 7.60547 5.16366 7.59258 5.16483C7.57969 5.166 7.55977 5.16366 7.54219 5.14725C7.52578 5.13319 7.51758 5.1121 7.51875 5.091C7.56211 4.38553 7.35117 3.58983 6.88945 2.72381C6.50742 2.00428 5.97656 1.44295 5.31328 1.05155L4.8293 0.766783C4.76602 0.729283 4.68516 0.778502 4.68867 0.85233L4.71445 1.41483C4.73203 1.7992 4.6875 2.13905 4.58203 2.42147C4.45312 2.76717 4.26797 3.08827 4.03125 3.37655C3.86651 3.5769 3.6798 3.75811 3.47461 3.91678C2.98044 4.29666 2.57861 4.78342 2.29922 5.34061C2.02052 5.90266 1.87534 6.52146 1.875 7.14881C1.875 7.70194 1.98398 8.23749 2.19961 8.74256C2.40781 9.22885 2.70827 9.67019 3.08438 10.0422C3.46406 10.4172 3.90469 10.7125 4.3957 10.9176C4.9043 11.1308 5.44336 11.2387 6 11.2387C6.55664 11.2387 7.0957 11.1308 7.6043 10.9187C8.0941 10.7149 8.53951 10.4175 8.91562 10.0433C9.29531 9.66835 9.59297 9.23006 9.80039 8.74374C10.0157 8.24004 10.1261 7.69777 10.125 7.14999C10.125 6.57811 10.0078 6.02264 9.77461 5.49882Z" fill="#FCE9A7"/>
			</svg>
			<span class="text-bold">
				{{ label.text }}
			</span>
		</div>
		<div class="product__label-item">
			<span class="text-light">
				{{ label.release_date }}
			</span>	
		</div>
	</div>

	<div class="product__label" v-else-if="keyType === 'pre_order' && label.class === 'pre-order'">
		<div class="product__label-item">
			<span class="text-light">
				{{ $t('Release') }}:&nbsp;
			</span>
		</div>
		<div class="product__label-item">
			<span class="text-bold">
				{{ label.release_date }}
			</span>	
		</div>
	</div>

	<div class="product__label-category" v-else>
		<span :class="['product__label-category-image', label.class]"></span>
		<span class="product__label-category-name">{{ label.text }}</span>
	</div>

</template>

<script>
  export default {
    props: {
      label: {
        type: Object,
        required: true
      },
			keyType: {
				type: String,
				required: false
			}
    }
  };
</script>

<style lang="scss" scoped>
	.product__label {
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(15px);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
    background-color: rgba(149, 116, 0, 0.5);
    padding: 8px 10px;
    border-radius: 4px 0;
    display: flex;
    align-items: center;
    z-index: 10;

    &-item {
      display: flex;
      align-items: center;
    }

    .g-6 {
      gap: 6px;
    }

    .border-right {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #FCE9A7;
    }

    .text-bold {
      font-family: "Roboto-Medium", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: #fce9a7;
    }

    .text-light {
      font-family: "Roboto-Light", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: #fce9a7;
    }
    
  }

	.product__label-category {
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(15px);
    box-shadow: 0px 4px 15px 0px #00000040;
    background-color: rgba(17, 83, 88, 0.5);
    padding: 5px 10px;
    border-radius: 4px 0;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 10;
    &-image {
      display: flex;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      &.dlc {
        background-image: url("/webpack-assets/img/common/label/dlc.svg");
      }
      &.pack {
        background-image: url("/webpack-assets/img/common/label/pack.svg");
      }
      &.bundle {
        background-image: url("/webpack-assets/img/common/label/bundle.svg");
      }
    }
    &-name {
      color: #92E5FF;
      font-family: 'Roboto-Medium', sans-serif;
      font-size: 14px;
      line-height: 1;
    }
  }
</style>
  