<template>
  <div class="banners-list" :class="{ 'keysforgamers': settings.company_name == 'Keysforgamers' }" ref="bannersList">
    <swiper
      v-if="mobile"
      :slides-per-view="1"
      :space-between="0"
      :effect="'creative'"
      :loop="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :pagination="{
        el: '.swiper-pagination',
        clickable: true,
      }"
      :modules="modules"
      :navigation="true"
      :allowTouchMove="true"

      @swiper="onSwiper"
      @slide-change="slideChange()"
    >
      <swiper-slide v-for="banner in banners" :key="banner.id" class="wrap-banner">
        <div class="container container-xl">
          <div class="banner">
            <img
              :src="banner.mobile_image"
              alt="Banner"
              class="banner-img mob"
              rel="preload" as="image"
            />
          </div>
          <div
            class="wrap-content animated"
            @mouseover="stopAutoplay"
            @mouseleave="startAutoplay"
          >
            <div class="banner-title">
              <a :href="banner.url">{{ banner.name }}</a>
            </div>
            <div class="banner-desc">{{ banner.description }}</div>

            <div class="banner-details keysforgamers" v-if="settings.company_name == 'Keysforgamers'">
              <div class="banner-details__price">
                <span class="banner-details__header">
                  <img
                    src="/img/pages/site/icons/price-tag.svg"
                    alt=""
                    rel="preload" as="image"
                  />
                  {{ $t("from") }}
                </span>
                <span class="banner-details__body">
                  <a :href="banner.url">{{ banner.price }}</a>
                </span>
              </div>
              <div class="divider vertical"></div>
              <div class="banner-details__platforms">
                <span class="banner-details__header">{{ $t("platforms") }}</span>
                <span class="banner-details__body">
                  <a
                    v-for="(platform, index) in banner.platforms"
                    :key="index"
                    :href="platform.url"
                    class="platform"
                    :class="'platform-' + (platform.platform_id || platform.id)"
                  ></a>
                </span>
              </div>
            </div>
            <div v-else class="banner-details">
              <div class="banner-details__price">
                <span class="banner-details__header">
                  <img
                    src="/img/pages/site/icons/price-tag.svg"
                    alt=""
                    rel="preload" as="image"
                  />
                  {{ $t("from") }}
                </span>
                <span class="banner-details__body">
                  <a :href="banner.url">{{ banner.price }}</a>
                </span>
              </div>
              <div class="divider vertical"></div>
              <div class="banner-details__platforms">
                <span class="banner-details__header">{{ $t("platforms") }}</span>
                <span class="banner-details__body">
                  <a
                    v-for="(platform, index) in banner.platforms"
                    :key="index"
                    :href="platform.url"
                    class="platform"
                    :class="'platform-' + (platform.platform_id || platform.id)"
                  ></a>
                </span>
              </div>
            </div>
          </div>
        </div>
        
      </swiper-slide>
      <div class="container container-xl dots"  :class="{ 'pause': isHovered, 'load': loading }">
      <div class="swiper-pagination"></div>
      </div>
    </swiper>
    <swiper
      v-else
      :slides-per-view="1"
      :space-between="0"
      :effect="'fade'"
      :loop="true"
      :allowTouchMove="false"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :pagination="{
        el: '.swiper-pagination',
        clickable: true,
      }"
      :modules="modules"
      :navigation="true"
      @swiper="onSwiper"
      @slide-change="slideChange()"
    >
      <swiper-slide v-for="banner in banners" :key="banner.id" class="wrap-banner">
        <div class="banner">
          <img :src="banner.image" rel="preload" as="image" alt="Banner" class="banner-img" />
        </div>
        <div class="container container-xl">
          <div
            class="wrap-content animated"
            @mouseover="stopAutoplay"
            @mouseleave="startAutoplay"
          >
            <div class="banner-title">
              <a :href="banner.url">{{ banner.name }}</a>
            </div>
            <div class="banner-desc">{{ banner.description }}</div>
            <div class="banner-details keysforgamers" v-if="settings.company_name == 'Keysforgamers'">
              <div class="banner-details__price">
                <div class="banner-details__price-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6522_19649)">
                    <path d="M19.2501 4H13.2501C12.8381 4 12.2611 4.239 11.9701 4.53L4.53109 11.969C4.39082 12.1099 4.31207 12.3007 4.31207 12.4995C4.31207 12.6983 4.39082 12.8891 4.53109 13.03L10.9701 19.469C11.111 19.6093 11.3018 19.688 11.5006 19.688C11.6994 19.688 11.8902 19.6093 12.0311 19.469L19.4701 12.03C19.7621 11.738 20.0001 11.162 20.0001 10.75V4.75C19.9993 4.55133 19.92 4.36102 19.7795 4.22054C19.6391 4.08006 19.4488 4.00079 19.2501 4ZM15.5001 10C15.303 9.99993 15.1079 9.96106 14.9259 9.88559C14.7439 9.81012 14.5785 9.69954 14.4392 9.56016C14.2999 9.42078 14.1895 9.25533 14.1141 9.07325C14.0388 8.89118 14 8.69605 14.0001 8.499C14.0002 8.30195 14.039 8.10685 14.1145 7.92482C14.19 7.7428 14.3005 7.57742 14.4399 7.43813C14.5793 7.29884 14.7448 7.18837 14.9268 7.11303C15.1089 7.03768 15.304 6.99893 15.5011 6.999C15.899 6.99913 16.2806 7.15735 16.562 7.43884C16.8433 7.72033 17.0012 8.10204 17.0011 8.5C17.001 8.89796 16.8427 9.27956 16.5612 9.56087C16.2798 9.84217 15.898 10.0001 15.5001 10Z" fill="#F6F6F6"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_6522_19649">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                    </clipPath>
                    </defs>
                    </svg>                    
                </div>
                <div class="banner-details__price-right">
                  <span class="banner-details__price-text">
                    {{ $t("Price") }}
                  </span>
                  <span class="banner-details__price-price">
                    <a :href="banner.url">{{ banner.price }}</a>
                  </span>
                </div>
              </div>
              <div class="banner-details__platforms">
                <span class="banner-details__header">{{ $t("platforms") }}</span>
                <span class="banner-details__body">
                  <a
                    v-for="(platform, index) in banner.platforms"
                    :key="index"
                    :href="platform.url"
                    class="platform"
                    :class="'platform-' + (platform.platform_id || platform.id)"
                  ></a>
                </span>
              </div>
            </div>
            <div class="banner-details" v-else>
              <div class="banner-details__price">
                <span class="banner-details__header">
                  <img
                    src="/img/pages/site/icons/price-tag.svg"
                    alt=""
                    rel="preload" as="image"
                  />
                  {{ $t("from") }}
                </span>
                <span class="banner-details__body">
                  <a :href="banner.url">{{ banner.price }}</a>
                </span>
              </div>
              <div class="divider vertical"></div>
              <div class="banner-details__platforms">
                <span class="banner-details__header">{{ $t("platforms") }}</span>
                <span class="banner-details__body">
                  <a
                    v-for="(platform, index) in banner.platforms"
                    :key="index"
                    :href="platform.url"
                    class="platform"
                    :class="'platform-' + (platform.platform_id || platform.id)"
                  ></a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div
        class="container container-xl dots"
        :class="{ 'pause': isHovered, 'load': loading }"
      >
        <div class="swiper-pagination"></div>
      </div>
    </swiper>
  </div>
</template>

<script>
import { LazyLoadDirective } from "../../../directives/LazyLoadDirective";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper/modules";

export default {
  directives: {
    lazyLoad: LazyLoadDirective,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const modules = [Autoplay, Pagination, Navigation, EffectFade];
    return {
      modules,
      banners: settings.banners,
    };
  },
  data() {
    return {
      banners: this.settings.banners,
      isHovered: false,
      autoplayTimeoutId: null,
      timeTimer: null,
      mySwiper: null,
      loading: false,
    };
  },
  created () {
   window.addEventListener('scroll', this.animate);
  },
  mounted() {
    const bannersListElement = this.$refs.bannersList;
    bannersListElement.addEventListener('scroll', this.animate);
    if(this.mobile){
        setTimeout(() => {
          this.loading = true;
      }, 3000);  
    } else {
        setTimeout(() => {
          this.loading = true;
      }, 1800);
    }
    this.setupAnimationEndListener();
  },
  methods: {
    setupAnimationEndListener() {
      this.$refs.bannersList.style.width = "calc(100% - 1px)";
      this.$nextTick(() => {
        const wrapContentElements = this.$el.querySelectorAll(".wrap-content");

        wrapContentElements.forEach((element) => {
          element.addEventListener("animationend", this.handleAnimationEnd);
        });
      });
    },
    handleAnimationEnd(event) {
      this.$refs.bannersList.style.width = "100%";
      this.$nextTick(() => {
        event.target.classList.remove("animated");
      });
    },
    updateBannerImg(yPosition) {
      const translateY = yPosition / 4;

      if (translateY < 250) {
        this.mySwiper.slides.forEach((img) => {
          const imgElement = img.querySelector('img');
          imgElement.style.transform = `translateY(${translateY}px)`;

          if (window.innerWidth < 768) {
            const x1 = 40 + yPosition / 20;
            const x2 = 50 + yPosition / 20;
            const maskGradient = `linear-gradient(0deg, transparent 0px ${yPosition}px, rgba(0, 0, 0, 0.5) ${x1}%, black ${x2}%)`;
            imgElement.style.maskImage = maskGradient;
          }
        });
      }
    },
    animate() {
      let yPosition = window.pageYOffset;
      if(yPosition < 600){
        this.updateBannerImg(yPosition);
      }
    },
    onSwiper(swiper) {
      this.mySwiper = swiper;
    },
    slideChange() {
      if(!this.mobile){
        if (this.mySwiper && !this.mySwiper.destroyed) {
          this.mySwiper.params.autoplay.delay = 5000;
        }
      }
    },
    stopAutoplay() {
      if (this.mySwiper) {
        this.mySwiper.autoplay.stop();
        this.timeTimer = this.mySwiper.autoplay.timeLeft;
        this.isHovered = true;
      }
    },
    startAutoplay() {
      if (this.mySwiper) {
        this.mySwiper.params.autoplay.delay = this.timeTimer;
        this.mySwiper.autoplay.start();
        this.isHovered = false;
      }
    },
  },
};
</script>