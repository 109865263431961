<template>
  <div class="categoty-section">
    <div class="container container-xl">
      <div class="row" v-if="!mobile">
        <div class="col-xs-6">
          <a :href="settings.urls.game_currency" rel="preload" >
            <div class="block block-1">
              <div class="block__bg"></div>
              <div class="block__info">
                <div class="block__content-title">
                  {{ $t("Game Currency") }}
                </div>
                <div class="block__content-text">
                  {{ $t("You can also find game currency from your favorite MMORPG at favorable prices.", { ns: 'site' }) }}
                </div>
                <div class="block__content-count">
                  <span>{{ settings.game_currency_count }}</span
                  >{{ $t("products") }}
                </div>
              </div>
              <div class="block__img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero_1.webp" alt=""/>
              </div>
              <div class="block__ellipse"></div>
            </div>
          </a>
        </div>
        <div class="col-xs-6">
          <a :href="settings.urls.game_points" rel="preload" >
            <div class="block block-2">
              <div class="block__bg"></div>
              <div class="block__info">
                <div class="block__content-title">
                  {{ $t("Game Points") }}
                </div>
                <div class="block__content-text">
                  {{ $t("You can also find game point from your favorite game at favorable prices.", { ns: 'site' }) }}
                </div>
                <div class="block__content-count">
                  <span>{{ settings.game_point_count }}</span
                  >{{ $t("products") }}
                </div>
              </div>
              <div class="block__img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero_2.webp" alt=""/>
              </div>
              <div class="block__ellipse"></div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" v-else>
        <Swiper
          v-if="isLoaded"
          :slides-per-view="'auto'"
          :loop="true"
          :centeredSlides="true"
          :initialSlide="1"
          noSwiping
          noSwipingSelector=""
          :breakpoints="{
            '767': {
              slidesPerView: 2,
              centeredSlides: false,
              loop: false,
              initialSlide: 1,
              noSwipingSelector: '.swiper-slide',
            },
          }"
        >
          <SwiperSlide>
            <div class="col-xs-12">
              <a :href="settings.urls.software" rel="preload" >
                <div class="block block-4">
                  <div class="block__bg"></div>
                  <div class="block__info">
                    <div class="block__content-title">{{ $t("Software") }}</div>
                    <div class="block__content-text">
                      {{ $t("You can also find software and operation systems at favorable prices.", { ns: 'site' }) }}
                    </div>
                    <div class="block__content-count">
                      <span>{{ settings.soft_count }}</span
                      >{{ $t("products") }}
                    </div>
                  </div>
                  <div class="block__img">
                    <img rel="preload" as="image" class="" src="/img/pages/index/hero_4.webp" alt="" />
                  </div>
                  <div class="block__ellipse"></div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="col-xs-12">
              <a :href="settings.urls.game_currency" rel="preload" >
                <div class="block block-1">
                  <div class="block__bg"></div>
                  <div class="block__info">
                    <div class="block__content-title">
                      {{ $t("Game Currency") }}
                    </div>
                    <div class="block__content-text">
                      {{ $t("You can also find game currency from your favorite MMORPG at favorable prices.", { ns: 'site' }) }}
                    </div>
                    <div class="block__content-count">
                      <span>{{ settings.game_currency_count }}</span
                      >{{ $t("products") }}
                    </div>
                  </div>
                  <div class="block__img">
                    <img rel="preload" as="image" class="" src="/img/pages/index/hero_1.webp" alt=""/>
                  </div>
                  <div class="block__ellipse"></div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="col-xs-12">
              <a :href="settings.urls.game_points" rel="preload" >
                <div class="block block-2">
                  <div class="block__bg"></div>
                  <div class="block__info">
                    <div class="block__content-title">
                      {{ $t("Game Points") }}
                    </div>
                    <div class="block__content-text">
                      {{ $t("You can also find game point from your favorite game at favorable prices.", { ns: 'site' }) }}
                    </div>
                    <div class="block__content-count">
                      <span>{{ settings.game_point_count }}</span
                      >{{ $t("products") }}
                    </div>
                  </div>
                  <div class="block__img">
                    <img rel="preload" as="image" class="" src="/img/pages/index/hero_2.webp" alt=""/>
                  </div>
                  <div class="block__ellipse"></div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="col-xs-12">
              <a :href="settings.urls.top_up" rel="preload" >
                <div class="block block-3">
                  <div class="block__bg"></div>
                  <div class="block__info">
                    <div class="block__content-title">
                      {{ $t("Gift Cards") }}
                    </div>
                    <div class="block__content-text">
                      {{ $t("You can also find a gift card to top up your favorite service.", { ns: 'site' }) }}
                    </div>
                    <div class="block__content-count">
                      <span>{{ settings.top_up_count }}</span
                      >{{ $t("products") }}
                    </div>
                  </div>
                  <div class="block__img">
                    <img rel="preload" as="image" class="" src="/img/pages/index/hero_3.webp" alt="" />
                  </div>
                  <div class="block__ellipse"></div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 500);
  },

};
</script>
