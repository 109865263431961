import { createApp } from 'vue';
import { loadMessages, translate } from '/webpack-assets/vue/utils/common';
import App from './../../templates/site/Index.vue';
import './../../templates/site/style/site.scss';
import '../../../scss/pages/cart-popup/popup-cart.scss';

const app = createApp(App);

const language = window.settings.language;

const controllers = ['site'];
const { messages } = loadMessages(language, controllers);

app.config.globalProperties.$t = (keyOrParams, ...params) => translate(keyOrParams, messages, ...params);

app.mount('#app').$nextTick(() => {
    document.body.removeAttribute('v-cloak');
});