<template>
    <div class="market__rating-number">
        {{ rating > 4.9 ? rating.toFixed() : rating.toFixed(1) }} / 5
      </div>
      <div class="market__rating-stars">
        <div class="rating-list">
            <span
            :class="{ active: rating >= 1 }"
            class="elem star-1"
            ></span>
            <span
            :class="{ active: rating >= 1.9 }"
            class="elem star-2"
            ></span>
            <span
            :class="{ active: rating >= 2.9 }"
            class="elem star-3"
            ></span>
            <span
            :class="{ active: rating >= 3.9 }"
            class="elem star-4"
            ></span>
            <span
            :class="{ active: rating >= 4.5 }"
            class="elem star-5"
            ></span>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      rating: {
        type: Number,
        default: undefined,
      },
    }
  };
  </script>
  
  <style scoped>
  .rating-list {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 4px;
    @media (max-width: 768px) {
      flex-direction: row;
      &.green {
        margin-top: 0;
      }
    }

    .elem {
      color: #4e5d74;
      margin-right: 7px;
      width: 15px;
      height: 15px;
      background-image: url("/webpack-assets/img/site/card/star.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      min-height: 15px;
      min-width: 15px;
      &.active {
        background-image: url("/webpack-assets/img/site/card/star-active.svg");
      }
    }
  }
</style>