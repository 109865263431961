<template>
  <div class="best-deals">
    <div class="deals_left">
      <a
        :href="blockUrl1"
         rel="preload"
        class="deals_img-block block-1"
        @mouseenter="handleMouseEnter('block-1')"
        @mouseleave="handleMouseLeave"
      >
        <div
          :class="{
            img: true,
            hover: hoveredBlock !== null && hoveredBlock !== 'block-1',
          }"
        ></div>
      </a>
      <a
        :href="blockUrl2"
         rel="preload"
        class="deals_img-block block-2"
        @mouseenter="handleMouseEnter('block-2')"
        @mouseleave="handleMouseLeave"
      >
        <div
          :class="{
            img: true,
            hover: hoveredBlock !== null && hoveredBlock !== 'block-2',
          }"
        ></div>
      </a>
    </div>
    <div class="deals_info" @mouseenter="handleMouseEnterAnimation" @mouseleave="handleMouseLeaveAnimation">
      <span class="deals_title">{{ $t("Best deals of the week", { ns: 'site' }) }}</span>
      <span class="deals_text">{{ $t("Do not miss the best offer of the week. Great discounts, nice prices and nice bonuses", { ns: 'site' }) }}</span>
      <div class="deals_animate-block">
        <div class="deals_info-timer" :class="{ 'animation-time': animationTime }">
          <div class="time">
            <span class="time_num hour">{{ formattedHours }}</span>
            <span class="time_title">{{ timeTranslate ? $t("Hour") : $t("Hours") }}</span>
          </div>
          <span class="time-dots">:</span>
          <div class="time">
            <span class="time_num min">{{ formattedMinutes }}</span>
            <span class="time_title">{{ $t("Minutes") }}</span>
          </div>
          <span class="time-dots">:</span>
          <div class="time">
            <span class="time_num sec">{{ formattedSeconds }}</span>
            <span class="time_title">{{ $t("Seconds") }}</span>
          </div>
        </div>
        <div class="deals_btn" :class="{ 'animation-btn': animationBtn }">
          <a :href="'/' + settings.language + '/products/order/weekly-offers'" class="btn-hover btn-hover--green js-btn-preloader-green-onClick" rel="preload" >
            <span>{{ $t("See all products") }}</span>
          </a>
        </div>
      </div>
      <div class="deals_info-ellipse"></div>
    </div>
    <div class="deals_right">
      <a
        :href="blockUrl3"
         rel="preload"
        class="deals_img-block block-3"
        @mouseenter="handleMouseEnter('block-3')"
        @mouseleave="handleMouseLeave"
      >
        <div
          :class="{
            img: true,
            hover: hoveredBlock !== null && hoveredBlock !== 'block-3',
          }"
        ></div>
      </a>
      <a
        :href="blockUrl4"
         rel="preload"
        class="deals_img-block block-4"
        @mouseenter="handleMouseEnter('block-4')"
        @mouseleave="handleMouseLeave"
      >
        <div
          :class="{
            img: true,
            hover: hoveredBlock !== null && hoveredBlock !== 'block-4',
          }"
        ></div>
      </a>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hoveredBlock: null,
      showPlaceholder: true,
      totalSeconds: this.calculateTimeUntilEndOfDay(),
      hours: 0,
      minutes: 0,
      seconds: 0,
      blockUrl1: "",
      blockUrl2: "",
      blockUrl3: "",
      blockUrl4: "",
      animationTime: true,
      animationBtn: false,
      timeTranslate: false,
    };
  },
  methods: {
    calculateTimeUntilEndOfDay() {
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
      return Math.floor((endOfDay - now) / 1000);
    },
    handleMouseLeaveAnimation() {
      this.animationBtn = false;
      setTimeout(() => {
        this.animationTime = true;
        this.animationBtn = false;
      }, 500);
    },
    handleMouseEnterAnimation() {
      this.animationTime = false;
      setTimeout(() => {
        this.animationBtn = true;
        this.animationTime = false;
      }, 500);
    },
    handleMouseLeave() {
      this.hoveredBlock = null;
    },
    handleMouseEnter(block) {
      this.hoveredBlock = block;
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value;
    },
    updateTime() {
      const intervalId = setInterval(() => {
        if (this.totalSeconds > 0) {
          this.totalSeconds--;
          this.hours = Math.floor(this.totalSeconds / 3600);
          this.minutes = Math.floor((this.totalSeconds % 3600) / 60);
          this.seconds = this.totalSeconds % 60;
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    },
  },
  computed: {
    formattedHours() {
      if (this.hours === 1) {
        this.timeTranslate = true;
      }
      return this.formatTime(this.hours);
    },
    formattedMinutes() {
      return this.formatTime(this.minutes);
    },
    formattedSeconds() {
      return this.formatTime(this.seconds);
    },
  },
  mounted() {
    const blockElement1 = document.querySelector(".block-1 .img");
    const blockElement2 = document.querySelector(".block-2 .img");
    const blockElement3 = document.querySelector(".block-3 .img");
    const blockElement4 = document.querySelector(".block-4 .img");
    if (this.settings.banner_weekly[0]) {
      blockElement1.style.backgroundImage = `url(${this.settings.banner_weekly[0].image})`;
      this.blockUrl1 = this.settings.banner_weekly[0].url;
    }
    if (this.settings.banner_weekly[1]) {
      blockElement2.style.backgroundImage = `url(${this.settings.banner_weekly[1].image})`;
      this.blockUrl2 = this.settings.banner_weekly[1].url;
    }
    if (this.settings.banner_weekly[2]) {
      blockElement3.style.backgroundImage = `url(${this.settings.banner_weekly[2].image})`;
      this.blockUrl3 = this.settings.banner_weekly[2].url;
    }
    if (this.settings.banner_weekly[3]) {
      blockElement4.style.backgroundImage = `url(${this.settings.banner_weekly[3].image})`;
      this.blockUrl4 = this.settings.banner_weekly[3].url;
    }
    setTimeout(() => {
      this.showPlaceholder = false;
      this.updateTime();
    }, 1500);
  },
};
</script>
