<template>
  <div class="categoty-section">
    <div class="container container-xl">
      <div class="row">
        <div v-for="(item, index) in categoryItems" :key="index" class="col-sm-6">
          <a :href="item.link" rel="preload" >
            <div :class="['block', `block-${index + 3}`]">
              <div class="block__bg"></div>
              <div class="block__info">
                <div class="block__content-title">{{ $t(item.title) }}</div>
                <div class="block__content-text">{{ $t(item.description) }}</div>
                <div class="block__content-count"><span>{{ item.count }}</span>{{ $t('products') }}</div>
              </div>
              <div class="block__img">
                <img rel="preload" as="image" class="" :src="`/img/pages/index/hero_${index + 3}.webp`" alt="">
              </div>
              <div class="block__ellipse"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      categoryItems: [
        {
          title: 'Gift Cards',
          description: 'You can also find a gift card to top up your favorite service.',
          count: settings.top_up_count,
          link: settings.urls.top_up,
        },
        {
          title: 'Software',
          description: 'You can also find software and operation systems at favorable prices.',
          count: settings.soft_count,
          link: settings.urls.software,
        },
      ],
    };
  },
};
</script>