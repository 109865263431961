<template>
  <div class="markets-slider" :class="{ 'keysforgamers': settings.company_name == 'Keysforgamers' }" v-lazy-load="fetchSellers">
    <div class="container container-xl">
      <div class="card-title-box">
        <div class="title">
          <h3>{{ $t("Popular sellers", { ns: 'site' }) }}</h3>
        </div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/user/trusted-sellers'" rel="preload">
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="row markets-list">
        <div
          class="placeholder-list"
          :class="{ opacity: showPlaceholderOpacity }"
          v-if="showPlaceholder"
        >
          <div v-for="index in 7" :key="index" class="placeholder-block">
            <div class="placeholder-block__top">
              <div class="placeholder-block__img placeholder"></div>
              <div class="placeholder-block__name">
                <div class="name placeholder"></div>
                <div class="rate placeholder"></div>
              </div>
            </div>
            <div class="placeholder-block__bottom">
              <div class="placeholder-block__text placeholder"></div>
              <div class="placeholder-block__text placeholder"></div>
              <div class="placeholder-block__link placeholder"></div>
            </div>
          </div>
        </div>
        <Swiper
            v-if="showSlider"
            ref="mySwiper"
            :lazy="true"
            :lazy-preloader="true"
            :slides-per-view="'auto'"
            :centeredSlides="true"
            :spaceBetween="0"
            :initialSlide="1"
            :slidesPerGroup="1"
            :modules="modules"
            :resizeObserver="false"
            :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
            :loop="true"
            :pagination="{
                  clickable: true,
                }"
            :autoplay="false"
            :speed="1000"
            :breakpoints="{
                  '767': {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    slidesPerGroup: 2,
                    initialSlide: 1
                  },
                  '990': {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    slidesPerGroup: 2,
                    initialSlide: 1
                  },
                  '1640': {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    slidesPerGroup: 3,
                    initialSlide: 3
                  },
                }"
            @swiper="onSwiper"
        >
          <SwiperSlide v-for="(seller, index) in sellers" :key="index">
            <a class="market-card" :href="seller.url" rel="preload"  @click="handleButtonClick">
              <div class="market-card__top">
                <a
                  class="market__img"
                  :href="seller.url"
                  rel="preload"
                >
                  <img :src="seller.image" alt="Seller image" rel="preload" as="image"/>
                  <span class="step-info inline ico-user-status-position">
                    <span class="step-info-i">
                      <div
                        :class="{
                          'ico-user-status': true,
                          online: seller.is_online,
                        }"
                      ></div>
                    </span>
                  </span>
                </a>
                <div class="market-card__top-right">
                  <span class="market__name">
                    {{ seller.name }}
                  </span>
                  <div class="market__rating">
                    <SellerStars :rating="seller.rate"/>
                  </div>
                </div>
              </div>
              <div class="market-card__bottom">
                <div class="membership-row">
                  <i
                    :class="[
                      'ico',
                      'achievement-cup',
                      `achievement-cup-${seller.membership_id}`,
                    ]"
                  ></i>
                  <h5 class="seller-buyer__title">
                    <p class="seller-buyer__count">{{ seller.membership }}</p>
                  </h5>
                </div>
                <a
                  class="market-card__bottom-view"
                  :href="seller.url"
                  rel="preload"
                >
                  <span>{{ $t("View") }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M16.4804 11.6902C16.4804 11.9411 16.3846 12.192 16.1934 12.3833L10.1736 18.403C9.79072 18.7859 9.16986 18.7859 8.78708 18.403C8.40431 18.0202 8.40431 17.3995 8.78708 17.0165L14.1137 11.6902L8.78727 6.36387C8.40449 5.98094 8.40449 5.36027 8.78727 4.97752C9.17004 4.59441 9.7909 4.59441 10.1738 4.97752L16.1936 10.9971C16.3848 11.1885 16.4804 11.4394 16.4804 11.6902Z"
                      fill="#C0C3C9"
                    />
                  </svg>
                </a>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
      <div ref="prev" class="swiper-button-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M8.01959 12.3098C8.01959 12.0589 8.11539 11.808 8.30659 11.6167L14.3264 5.59701C14.7093 5.21408 15.3301 5.21408 15.7129 5.59701C16.0957 5.97979 16.0957 6.60052 15.7129 6.98349L10.3863 12.3098L15.7127 17.6361C16.0955 18.0191 16.0955 18.6397 15.7127 19.0225C15.33 19.4056 14.7091 19.4056 14.3262 19.0225L8.30641 13.0029C8.11517 12.8115 8.01959 12.5606 8.01959 12.3098Z" fill="#F6F6F6"/>
        </svg>
      </div>
      <div ref="next" class="swiper-button-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15.9804 11.6902C15.9804 11.9411 15.8846 12.192 15.6934 12.3833L9.67365 18.403C9.29072 18.7859 8.66986 18.7859 8.28708 18.403C7.90431 18.0202 7.90431 17.3995 8.28708 17.0165L13.6137 11.6902L8.28727 6.36387C7.90449 5.98094 7.90449 5.36027 8.28727 4.97752C8.67004 4.59441 9.2909 4.59441 9.67383 4.97752L15.6936 10.9971C15.8848 11.1885 15.9804 11.4394 15.9804 11.6902Z" fill="#F6F6F6"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import { LazyLoadDirective } from "../../../directives/LazyLoadDirective";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation } from "swiper/modules";
import SellerStars from '../../components/sellerStars.vue';

export default {
  directives: {
    lazyLoad: LazyLoadDirective,
  },
  components: {
    Swiper,
    SwiperSlide,
    SellerStars
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const sellers = reactive([]);
    const showPlaceholder = ref(true);
    const showPlaceholderOpacity = ref(false);
    const showSlider = ref(false);
    const mySwiper = ref(null);

    const fetchSellers = async () => {
      try {
        const response = await axios.get(`/${settings.language}/site-api/sellers`);
        const sellersData = response.data;
        const lastElement = sellersData.pop();
        sellersData.unshift(lastElement);
        sellers.push(...sellersData);
        showPlaceholderOpacity.value = true;
        showSlider.value = true;
        setTimeout(() => {
          showPlaceholder.value = false;
        }, 1500);
      } catch (error) {
        console.error("Error response:", error);
      }
    };

    const handleButtonClick = (event) => {
      const target = event.currentTarget;
      target.classList.add("preload");
      setTimeout(() => {
        target.classList.remove("preload");
      }, 2500);
    };

    onMounted(fetchSellers);

    return {
      modules: [Autoplay, Navigation],
      prev,
      next,
      sellers,
      showPlaceholder,
      showPlaceholderOpacity,
      showSlider,
      mySwiper,
      fetchSellers,
      handleButtonClick,
    };
  },
};
</script>
