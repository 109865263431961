<template>
    <div class="platform-section keysforgamers" >
      <div class="container container-xl">
        <div class="card-title-box">
          <div class="title">
            <h3>{{ $t("Platforms") }}</h3>
          </div>
        </div>
        <div class="row" v-if="!mobile">
          <div class="col-sm-6 col-md-3" v-for="(platform, index) in platforms" :key="index">
            <a :href="`/${settings.language}/${platform.slug}`" rel="preload" >
              <div class="block-kfg">
                <div  class="block-kfg__img">
                    <div class="block-kfg__img-bg" :class="`block-kfg__img-bg block__${index + 1}`">
                        <img rel="preload" as="image" class="img-block" :src="`/img/pages/site/${platform.image}`" alt=""/>
                    </div>
                </div>
                <div class="block-kfg__text">
                    <div class="block-kfg__text-title">{{ platform.name }}</div>
                    <div class="block-kfg__text-description">{{ platform.text }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="row" v-else>
          <Swiper
          class="platform-slider"
          :slides-per-view="'auto'"
          :loop="true"
          :centeredSlides="true"
          :initialSlide="1"
        >
          <SwiperSlide v-for="(platform, index) in platforms" :key="index">
            <a :href="`/${settings.language}/${platform.slug}`" rel="preload" >
              <div class="block-kfg">
                <div  class="block-kfg__img">
                    <div class="block-kfg__img-bg" :class="`block-kfg__img-bg block__${index + 1}`">
                        <img rel="preload" as="image" class="img-block" :src="`/img/pages/site/${platform.image}`" alt=""/>
                    </div>
                </div>
                <div class="block-kfg__text">
                    <div class="block-kfg__text-title">{{ platform.name }}</div>
                    <div class="block-kfg__text-description">{{ platform.text }}</div>
                </div>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  export default {
    props: {
      mobile: {
        type: Boolean,
        default: false,
      },
      settings: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        platforms: [
          { name: "PC", slug: "products/platform/pc", image: "pc-kfg.png", text: "Epic Games, Steam, EA sports, Battle.net, GOG, Rockstar" },
          { name: "Playstation", slug: "buy-console-games/sony-playstation", image: "ps-kfg.png", text: "Playstation 5, Playstation 4" },
          { name: "Xbox", slug: "buy-console-games/xbox-360onexbox-series-x", image: "xbox-kfg.png", text: "Xbox Series X, Series S, Xbox One, Xbox 360" },
          { name: "Nintendo Switch", slug: "products/platform/switch", image: "nintendo-kfg.png", text: "Nintendo Switch, Eshop gift cards, Nintendo membership" },
        ],
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
  };
  </script>