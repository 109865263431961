<template>
  <div class="offer-type-section" :class="{ 'keysforgamers': settings.company_name == 'Keysforgamers' }" v-lazy-load="fetchOffers">
    <div class="container container-xl">
      <div v-if="keyType === NEW_ON_SITE" class="card-title-box">
        <div class="title"><h3>{{ $t('New on {msg}', {msg: settings.company_name, ns: 'site'}) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/products/type/new-offers'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div v-else-if="keyType === WEEKLY_CHART" class="card-title-box">
        <div class="title"><h3>{{ $t('Weekly chart', { ns: 'site' }) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/products/order/weekly-offers'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div v-else-if="keyType === UNDER_TEN" class="card-title-box">
        <div class="title"><h3>{{ $t('Under {cur}', {cur: '€10', ns: 'site'}) }}</h3></div>
        <div class="view_all" @click="handleButtonClick">
          <a :href="'/' + settings.language + '/products/type/under-ten-offers'" rel="preload" >
            <span>{{ $t('View All') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M15.9804 11.6905C15.9804 11.9414 15.8846 12.1922 15.6934 12.3835L9.67365 18.4032C9.29072 18.7862 8.66986 18.7862 8.28708 18.4032C7.90431 18.0205 7.90431 17.3997 8.28708 17.0168L13.6137 11.6905L8.28727 6.36412C7.90449 5.98118 7.90449 5.36051 8.28727 4.97777C8.67004 4.59465 9.2909 4.59465 9.67383 4.97777L15.6936 10.9974C15.8848 11.1888 15.9804 11.4396 15.9804 11.6905Z"
                  fill="#C0C3C9"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="row placeholder-list" :class="{ 'opacity': showPlaceholderOpacity }" v-if="showPlaceholder">
        <div v-for="index in 8" :key="index" class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
          <div class="catalog-card placeholder-block ">
            <div class="placeholder-block__img placeholder"></div>
            <div class="placeholder-block__info">
              <div class="placeholder-block__info-person">
                <div class="avatar placeholder"></div>
                <div class="placeholder-block__info-person-name">
                  <div class="name placeholder"></div>
                  <div class="rank placeholder"></div>
                </div>
              </div>
              <div class="placeholder-block__star placeholder"></div>
            </div>
            <div class="placeholder-block__title">
              <div class="placeholder w-75"></div>
              <div class="placeholder w-25"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!tablet">
        <div class="col-xs-12 col-md-4 col-xl-3" v-for="(offer, index) in offers" :key="index">
          <div class="catalog-card catalog-card__wrap catalog-card__wrap--ajax-load card js-video-container"
                  :class="{ 'weekly': keyType === WEEKLY_CHART }"
                   @mouseenter="playVideo(offer.id)" @mouseleave="pauseVideo(offer.id)" :id="offer.id">
            <ProductLabel 
              v-if="offer.label.text" 
              :label="offer.label"
              :keyType="keyType"
            />
              <div class="catalog-card__item-flex-wrap">
                <div class="catalog-card__img-wrap-container">
                  <div class="catalog-card__img-wrap">
                    <div class="catalog-card__img-wrap-static">
                      <div class="catalog-card__img-link" href="">
                        <img :src="offer.image"
                              alt="Offer image"
                              rel="preload" as="image"
                        >
                      </div>
                    </div>
                    <div class="catalog-card__img-wrap-animate" :class="{'play': playVideoClass[offer.id] }">
                      <a class="catalog-card__img-link" :href="offer.url" rel="preload" >
                        <div class="catalog-card__video-container" :data-video="offer.video" v-if="isHover[offer.id]">
                          <video :ref="`videoPlayer-${offer.id}`" class="js-video-player-vue" loop muted preload="none">
                            <source type="video/mp4" src="">
                          </video>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="catalog-card__product-wrap">
                    <div class="catalog-card__add-to-cart">
                      <button :class="{'preload': isLoading[offer.id] }"
                              v-if="!offer.is_in_cart"
                              class="catalog-card__btn"
                              @click="addToCart(offer.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                  <path d="M16.3561 7.38463C16.3019 7.32102 16.2348 7.26995 16.1591 7.23493C16.0835 7.19992 16.0012 7.18179 15.918 7.18179H12.4592V4.86363C12.4592 4.40251 12.277 3.96029 11.9527 3.63423C11.6284 3.30818 11.1885 3.125 10.7298 3.125H7.2711C6.81244 3.125 6.37257 3.30818 6.04825 3.63423C5.72393 3.96029 5.54173 4.40251 5.54173 4.86363V7.18179H2.08298C1.99942 7.18084 1.91666 7.19817 1.84042 7.23257C1.76418 7.26697 1.69629 7.31762 1.64146 7.38102C1.58663 7.44441 1.54616 7.51904 1.52286 7.59972C1.49956 7.6804 1.49399 7.76521 1.50653 7.84827L2.59027 14.8897C2.63196 15.1661 2.7716 15.418 2.98347 15.5991C3.19533 15.7801 3.46514 15.8781 3.74318 15.8749H14.2693C14.5473 15.8781 14.8171 15.7801 15.029 15.5991C15.2409 15.418 15.3805 15.1661 15.4222 14.8897L16.4944 7.84827C16.506 7.76553 16.4996 7.68126 16.4757 7.60123C16.4518 7.52121 16.411 7.44732 16.3561 7.38463ZM6.69464 4.86363C6.69464 4.70992 6.75537 4.56251 6.86348 4.45383C6.97159 4.34514 7.11821 4.28408 7.2711 4.28408H10.7298C10.8827 4.28408 11.0294 4.34514 11.1375 4.45383C11.2456 4.56251 11.3063 4.70992 11.3063 4.86363V7.18179H6.69464V4.86363ZM14.2693 14.7158H3.73165L2.75744 8.34088H15.2435L14.2693 14.7158Z" fill="#F6F6F6"/>
                                  <path d="M14.2693 14.7158H3.73165L2.75744 8.34088H15.2435L14.2693 14.7158Z" fill="#F6F6F6"/>
                                </svg>
                        <span>{{ $t('Add to cart') }}</span>
                      </button>
                      <button :class="{'preload': isLoading[offer.id] }"
                              v-else
                              class="catalog-card__btn remove-from-cart"
                              @click="removeFromCart(offer.id)">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                                                  viewBox="0 0 12 16" fill="none">
                                                <g clip-path="url(#clip0_3186_3745)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M2.66601 15.2749C1.74935 15.2749 0.99935 14.5249 0.99935 13.6083V3.60823H10.9993V13.6083C10.9993 14.5249 10.2493 15.2749 9.33269 15.2749H2.66601ZM8.91599 1.10823H11.8327V2.7749H0.166016V1.10823H3.08268L3.91602 0.274902H8.08267L8.91599 1.10823ZM3.12455 7.00628L5.39293 9.27466L3.12455 11.543C2.95718 11.7104 2.95718 11.9818 3.12455 12.1491C3.29192 12.3165 3.56327 12.3165 3.73064 12.1491L5.99902 9.88075L8.26741 12.1491C8.43477 12.3165 8.70613 12.3165 8.8735 12.1491C9.04086 11.9818 9.04086 11.7104 8.8735 11.543L6.60511 9.27466L8.8735 7.00628C9.04086 6.83891 9.04086 6.56755 8.8735 6.40018C8.70613 6.23282 8.43477 6.23282 8.26741 6.40018L5.99902 8.66857L3.73064 6.40018C3.56327 6.23282 3.29192 6.23282 3.12455 6.40018C2.95718 6.56755 2.95718 6.83891 3.12455 7.00628Z"
                                                      fill="#F6F6F6"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_3186_3745">
                                                <rect width="12" height="15" fill="white"
                                                      transform="translate(0 0.275391)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                        <span class="text">{{ $t('Remove') }}</span>
                      </button>
                    </div>
                    <div class="catalog-card__user-info">
                      <div class="catalog-card__user-avatar">
                        <a :href="offer.seller?.seller_url" rel="preload" >
                          <img
                              :src="offer.seller?.image"
                              class="img-circle img-responsive"
                              :alt="offer.seller?.name"
                              rel="preload" as="image"
                          />
                        </a>
                        <span class="step-info inline ico-user-status-position">
                                              <span class="step-info-i">
                                                  <div
                                                      :class="{ 'ico-user-status': true, 'online': offer.seller?.is_online }"></div>
                                              </span>
                                          </span>
                      </div>
                      <div class="catalog-card__user-info-wrap">
                        <div class="catalog-card__user-name-wrap">
                          <a class="catalog-card__user-name" :href="offer.seller?.seller_url" rel="preload" >
                            {{ offer.seller?.name }}
                          </a>
                          <TrustedIcon :verifyStatus="offer.seller?.is_business"/>
                        </div>
                        <div class="catalog-card__achievement-wrap">
                                              <span
                                                  class="catalog-card__achievement-text catalog-card__achievement-text--gray">
                                                  {{ $t('Rank:') }}
                                              </span>
                          <span>
                                                  <i :class="['catalog-card__achievement-icn', 'ico', 'achievement-cup', `achievement-cup-${offer.seller?.membership_id}`]"></i>
                                                  <span class="catalog-card__achievement-text">
                                                      {{ offer.seller?.membership }}
                                                  </span>
                                              </span>
                        </div>
                      </div>
                    </div>
                    <div class="catalog-card__bookmark-wrap">
                      <div class="bookmark-btn__wrap bookmark-btn__wrap--bookmark" v-if="!settings.is_guest">
                        <a data-href="" @click="toggleBookmark(offer.id)"
                           rel="preload"
                          class="bookmark-btn__btn-link bookmark-toggle-vue">
                          <div :class="{ 'active': offer.is_bookmark }" class="bookmark-btn__btn">
                            <div class="bookmark-btn__btn-img">
                              <img rel="preload" as="image" class="nohov" src="/img/to-bookmarks-btn/bookmark-star.svg" alt="">
                              <img rel="preload" as="image" class="hov" src="/img/to-bookmarks-btn/bookmark-star-hover.svg" alt="">
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="catalog-card__animate-block">
                  <div class="catalog-card__info-wrap">
                    <a class="catalog-card__card-link" :href="offer.url" rel="preload" >{{ offer.name }}</a>
                    <p class="catalog-card__price">{{ offer.price }}</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row" v-else>
        <Swiper
            v-if="showSlider"
            :slides-per-view="'auto'"
            :centeredSlides="true"
            :no-swiping="true"
            :loop="true"
            :initialSlide="1"
            :breakpoints="{
              '767': {
                slidesPerView: 2,
                loop: true,
                centeredSlides: false,
                initialSlide: 0,
              },
            }"
        >
          <SwiperSlide v-for="(offer, index) in offers" :key="index">
            <div class="col-xs-12">
              <div class="catalog-card catalog-card__wrap catalog-card__wrap--ajax-load card js-video-container"
                  :class="{ 'weekly': keyType === WEEKLY_CHART }"
                   @mouseenter="playVideo(offer.id)" @mouseleave="pauseVideo(offer.id)" :id="offer.id">
                <ProductLabel 
                  v-if="offer.label.text" 
                  :label="offer.label"
                  :keyType="keyType"
                />
                <div class="catalog-card__item-flex-wrap">
                  <div class="catalog-card__img-wrap-container">
                    <div class="catalog-card__add-to-cart">
                      <button :class="{'preload': isLoading[offer.id] }"
                              v-if="!offer.is_in_cart"
                              class="catalog-card__btn"
                              @click="addToCart(offer.id)">
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M2.44151 14.4122C2.48861 14.724 2.64625 15.0087 2.88558 15.2132C3.12476 15.4176 3.42944 15.5284 3.74358 15.525H14.268C14.5821 15.5284 14.8868 15.4176 15.126 15.2132C15.3654 15.0086 15.523 14.7241 15.5701 14.4122L16.6423 7.37095L16.6425 7.36909C16.6571 7.26505 16.649 7.15909 16.619 7.05844C16.5891 6.95813 16.538 6.86543 16.4691 6.78667C16.4011 6.70697 16.3167 6.64291 16.2217 6.59891C16.1263 6.55476 16.0226 6.53189 15.9175 6.53189H12.6088V4.36372C12.6088 3.86309 12.411 3.38279 12.0586 3.02855C11.7062 2.67427 11.2281 2.4751 10.7294 2.4751H7.27066C6.77196 2.4751 6.29385 2.67427 5.94146 3.02855C5.5891 3.38279 5.39129 3.86309 5.39129 4.36372V6.53189H2.08335C1.97823 6.53083 1.87415 6.55269 1.77829 6.59594C1.68216 6.63932 1.59662 6.70316 1.52757 6.78299C1.45853 6.86281 1.40761 6.95673 1.37831 7.0582C1.34901 7.15967 1.34207 7.26674 1.35784 7.37118L2.44151 14.4122ZM6.96939 4.05971C6.88933 4.1402 6.8442 4.24954 6.8442 4.36372V6.53189H11.1559V4.36372C11.1559 4.24954 11.1107 4.1402 11.0307 4.05971C10.9506 3.97924 10.8422 3.93418 10.7294 3.93418H7.27066C7.15782 3.93418 7.04943 3.97924 6.96939 4.05971ZM3.86003 14.0659L2.93167 7.99098H4.58049H15.0684L14.2087 13.6167L14.14 14.0659H3.86003Z"
                                              fill="#fff"/>
                                    </svg>
                                </span>
                      </button>
                      <button :class="{'preload': isLoading[offer.id] }"
                              v-else
                              class="catalog-card__btn remove-from-cart"
                              @click="removeFromCart(offer.id)">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                                        viewBox="0 0 12 16" fill="none">
                                      <g clip-path="url(#clip0_3186_3745)">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2.66601 15.2749C1.74935 15.2749 0.99935 14.5249 0.99935 13.6083V3.60823H10.9993V13.6083C10.9993 14.5249 10.2493 15.2749 9.33269 15.2749H2.66601ZM8.91599 1.10823H11.8327V2.7749H0.166016V1.10823H3.08268L3.91602 0.274902H8.08267L8.91599 1.10823ZM3.12455 7.00628L5.39293 9.27466L3.12455 11.543C2.95718 11.7104 2.95718 11.9818 3.12455 12.1491C3.29192 12.3165 3.56327 12.3165 3.73064 12.1491L5.99902 9.88075L8.26741 12.1491C8.43477 12.3165 8.70613 12.3165 8.8735 12.1491C9.04086 11.9818 9.04086 11.7104 8.8735 11.543L6.60511 9.27466L8.8735 7.00628C9.04086 6.83891 9.04086 6.56755 8.8735 6.40018C8.70613 6.23282 8.43477 6.23282 8.26741 6.40018L5.99902 8.66857L3.73064 6.40018C3.56327 6.23282 3.29192 6.23282 3.12455 6.40018C2.95718 6.56755 2.95718 6.83891 3.12455 7.00628Z"
                                            fill="#F6F6F6"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_3186_3745">
                                      <rect width="12" height="15" fill="white"
                                            transform="translate(0 0.275391)"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                                </span>
                      </button>
                    </div>
                    <div class="catalog-card__img-wrap">
                      <div class="catalog-card__img-wrap-static">
                        <div class="catalog-card__img-link" href="">
                          <img :src="offer.image"
                               alt="Offer image"
                               rel="preload" as="image"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="catalog-card__product-wrap">
                      <div class="catalog-card__user-info">
                        <div class="catalog-card__user-avatar">
                          <a :href="offer.seller?.seller_url" rel="preload" >
                            <img
                                :src="offer.seller?.image"
                                class="img-circle img-responsive"
                                :alt="offer.seller?.name"
                                rel="preload" as="image"
                            />
                          </a>
                          <span class="step-info inline ico-user-status-position">
                                                <span class="step-info-i">
                                                    <div
                                                        :class="{ 'ico-user-status': true, 'online': offer.seller?.is_online }"></div>
                                                </span>
                                            </span>
                        </div>
                        <div class="catalog-card__user-info-wrap">
                          <div class="catalog-card__user-name-wrap">
                            <a class="catalog-card__user-name" :href="offer.seller?.seller_url" rel="preload" >
                              {{ offer.seller?.name }}
                            </a>
                            <TrustedIcon :verifyStatus="offer.seller?.is_business"/>
                          </div>
                          <div class="catalog-card__achievement-wrap">
                                                <span
                                                    class="catalog-card__achievement-text catalog-card__achievement-text--gray">
                                                    {{ $t('Rank:') }}
                                                </span>
                            <span>
                                                    <i :class="['catalog-card__achievement-icn', 'ico', 'achievement-cup', `achievement-cup-${offer.seller?.membership_id}`]"></i>
                                                    <span class="catalog-card__achievement-text">
                                                        {{ offer.seller?.membership }}
                                                    </span>
                                                </span>
                          </div>
                        </div>
                      </div>
                      <div class="catalog-card__bookmark-wrap">
                        <div class="bookmark-btn__wrap bookmark-btn__wrap--bookmark" v-if="!settings.is_guest">
                          <a data-href="" @click="toggleBookmark(offer.id)"
                             rel="preload"
                             class="bookmark-btn__btn-link bookmark-toggle-vue">
                            <div :class="{ 'active': offer.is_bookmark }" class="bookmark-btn__btn">
                              <div class="bookmark-btn__btn-img">
                                <img rel="preload" as="image" class="nohov" src="/img/to-bookmarks-btn/bookmark-star.svg" alt="">
                                <img rel="preload" as="image" class="hov" src="/img/to-bookmarks-btn/bookmark-star-hover.svg" alt="">
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="catalog-card__animate-block">
                    <div class="catalog-card__info-wrap">
                      <a class="catalog-card__card-link" :href="offer.url" rel="preload" >{{ offer.name }}</a>
                      <p class="catalog-card__price">{{ offer.price }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '../../../config/axios-config';
import {LazyLoadDirective} from '../../../directives/LazyLoadDirective';
import {NEW_ON_SITE, WEEKLY_CHART, UNDER_TEN} from '../../../const/site/index/offers';
import {Swiper, SwiperSlide} from 'swiper/vue';
import TrustedIcon from '../../components/trustedIcon.vue';

import ProductLabel from '../../components/ProductLabel.vue';

export default {
  directives: {
    lazyLoad: LazyLoadDirective
  },
  props: {
    keyType: String,
    tablet: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: null,
    },
    offer: {
      type: Object,
      default: () => ({
        label: {
          text: '',
          class: '',
          category_id: ''
        }
      })
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    TrustedIcon,
    ProductLabel
  },
  data() {
    return {
      showPlaceholder: true,
      showPlaceholderOpacity: false,
      isLoading: {},
      playVideoClass: {},
      isHover: {},
      showSlider: false,
      offers: [],
      NEW_ON_SITE,
      WEEKLY_CHART,
      UNDER_TEN,
    };
  },
  created() {
    window.addEventListener('updateIsAddedToCart', this.handleUpdateIsAddedToCart);
  },
  destroyed() {
    window.removeEventListener('updateIsAddedToCart', this.handleUpdateIsAddedToCart);
  },
  methods: {
    handleButtonClick(event) {
      const target = event.currentTarget;
      target.classList.add('preload');
      setTimeout(() => {
        target.classList.remove('preload');
      }, 2500);
    },
    playVideo(id) {
      this.isHover[id] = true;

      this.$nextTick(() => {
        const videoPlayer = this.$refs[`videoPlayer-${id}`];
        if (!videoPlayer || !videoPlayer[0]) {
          console.error(`Video player with id ${id} not found or is not properly initialized.`);
          return;
        }

        const videoSource = videoPlayer[0].closest('.catalog-card__video-container').getAttribute('data-video');

        if (this.isHover[id]) {
          Object.keys(this.playVideoClass).forEach(key => {
            this.playVideoClass[key] = false;
          });

          if (videoSource) {
            this.playVideoClass[id] = true;
            const sourceElement = videoPlayer[0].querySelector('source');
            sourceElement.setAttribute('src', videoSource);
            videoPlayer[0].load();

            videoPlayer[0].addEventListener('loadeddata', () => {
              videoPlayer[0].play().then(() => {
                this.playVideoClass[id] = true;
              }).catch(error => {
                console.error('Error playing the video:', error);
              });
            }, { once: true });
          }
        }
      });
    },
    pauseVideo(id) {
      const videoPlayer = this.$refs[`videoPlayer-${id}`];
      if (videoPlayer && videoPlayer[0]) {
        const sourceElement = videoPlayer[0].querySelector('source');
        this.playVideoClass[id] = false;

        if (sourceElement) {
          sourceElement.setAttribute('src', '');
        }

        if (typeof videoPlayer[0].pause === 'function') {
          videoPlayer[0].pause();
        }
      } else {
        console.error(`Video player with id ${id} not found or is not properly initialized.`);
      }
      this.isHover[id] = false;
    },
    handleUpdateIsAddedToCart(event) {
      const {itemId} = event.detail;
      const index = this.offers.findIndex(offer => offer.id === itemId);
      this.offers[index] = {...this.offers[index], is_in_cart: false};
    },
    toggleBookmark(offerId) {
      const index = this.offers.findIndex(offer => offer.id === offerId);
      this.offers[index] = {...this.offers[index], is_bookmark: !this.offers[index].is_bookmark};
      axios.get(`/` + this.settings.language + `/bookmark/toggle?offer_id=${offerId}`)
          .catch(error => {
            this.offers[index] = {...this.offers[index], is_bookmark: false};
            console.error('Error toggling bookmark:', error);
          })
    },
    addToCart(offerId) {
      this.isLoading[offerId] = true;
      axios.post(`/cart/add/${offerId}`, {})
          .then(() => {
            const index = this.offers.findIndex(offer => offer.id === offerId);
            this.offers[index] = {...this.offers[index], is_in_cart: true};
            window.updateMiniCarts(1, offerId);
            $('#cardModal').modal('show');
          })
          .catch(error => {
            window.location.href = '/signup';
            console.error('Error adding to cart:', error);
          })
          .finally(() => {
            this.isLoading[offerId] = false;
          });
    },
    removeFromCart(offerId) {
      this.isLoading[offerId] = true;
      setTimeout(() => {
        const index = this.offers.findIndex(offer => offer.id === offerId);
        this.offers[index] = {...this.offers[index], is_in_cart: false};
        this.isLoading[offerId] = false;
        window.updateMiniCarts(0, offerId);
      }, 500)

    },
    fetchOffers() {
      axios.get('/' + this.settings.language + '/site-api/offers?type=' + this.keyType)
          .then(response => {
            this.offers = response.data;
            this.showPlaceholderOpacity = true;
            this.showSlider = true;
            setTimeout(() => {
              this.showPlaceholder = false;
            }, 1000)
          })
          .catch(error => {
            console.error('Error response:', error);
          });
    }
  }
};
</script>
