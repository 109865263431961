<template>
    <div class="categoty-section-kfg">
        <a :href="settings.urls.game_currency" rel="preload" class="categoty-section-kfg__block block-1">
            <div class="categoty-section-kfg__block-img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero-kfg-1.webp" alt="" />
            </div>
            <div class="categoty-section-kfg__block-text">
                <h3>{{ $t("Game Currency") }}</h3>
                <p>{{ $t("You can also find game currency from your favorite MMORPG at favorable prices.", { ns: 'site' }) }}</p>
            </div>
        </a>
        <a :href="settings.urls.game_points" rel="preload" class="categoty-section-kfg__block block-2">
            <div class="categoty-section-kfg__block-text">
                <h3>{{ $t("Game Points") }}</h3>
                <p>{{ $t("You can also find game point from your favorite game at favorable prices.", { ns: 'site' }) }}</p>
            </div>
            <div class="categoty-section-kfg__block-img">
                <img rel="preload" as="image" class="" src="/img/pages/index/hero-kfg-2.webp" alt="" />
            </div>
        </a>
    </div>
  </template>
  <script>
    export default {
    props: {
        settings: {
        type: Object,
        default: null,
        },
    },

    };
</script>