<template>
    <div class="discover-shopping-world-kfg">
        <a :href="'/' + settings.language + '/products/order/popular'" rel="preload" >
            <div class="container container-xl">
                <div class="col-sm-6">
                    <div class="discover-shopping-world-kfg__text">
                        <h2>{{ $t("Discover the best shopping world", { ns: 'site' }) }}</h2>
                        <p>
                            {{ $t("Buy your favourite games for a low price.", { ns: 'site' }) }}
                            <br>
                            {{ $t("Discounts, Prices, Bonuses for Games", { ns: 'site' }) }}
                        </p>
                    </div>
                    <div class="discover-shopping-world-kfg__img-mobile" v-if="mobile">
                        <div class="discover-shopping-world-kfg__img-mobile-el left">
                            <div class="element" v-for="(elem, index) in first" :key="index">
                                <a :href="elem.url">
                                    <img :src="elem.image" :alt="elem.name">
                                </a>
                            </div>
                        </div>
                        <div class="discover-shopping-world-kfg__img-mobile-el right">
                            <div class="element" v-for="(elem, index) in second" :key="index">
                                <a :href="elem.url">
                                    <img :src="elem.image" :alt="elem.name">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mobile-none" v-if="!mobile">
                    <div class="discover-shopping-world-kfg__animation">
                        <div class="discover-shopping-world-kfg__animation-el top">
                            <div class="element" v-for="(elem, index) in first" :key="index">
                                <a :href="elem.url">
                                    <img :src="elem.image" :alt="elem.name">
                                </a>
                            </div>
                        </div>
                        <div class="discover-shopping-world-kfg__animation-el bottom">
                            <div class="element" v-for="(elem, index) in second" :key="index">
                                <a :href="elem.url">
                                    <img :src="elem.image" :alt="elem.name">
                                </a>
                            </div>
                        </div>
                        <div class="discover-shopping-world-kfg__animation-el top last">
                            <div class="element" v-for="(elem, index) in third" :key="index">
                                <a :href="elem.url">
                                    <img :src="elem.image" :alt="elem.name">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    settings: {
      type: Object,
      default: null,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      first: [],
      second: [],
      third: []
    };
  },
  mounted() {
    if (Array.isArray(this.settings.banner_discover)) {
        const bannerDiscoverArray = Object.values(this.settings.banner_discover);
        if(window.innerWidth < 768){
            const chunkSize = Math.ceil(this.settings.banner_discover.length / 2);
        
            for (let i = 0; i < bannerDiscoverArray.length; i++) {
            if (i < chunkSize) {
                    this.first.push(bannerDiscoverArray[i]);
                } else {
                    this.second.push(bannerDiscoverArray[i]);
                } 
            }
        } else {
            const chunkSize = Math.ceil(this.settings.banner_discover.length / 3);
        
            for (let i = 0; i < bannerDiscoverArray.length; i++) {
            if (i < chunkSize) {
                    this.first.push(bannerDiscoverArray[i]);
                } else if (i < chunkSize * 2) {
                    this.second.push(bannerDiscoverArray[i]);
                } else {
                    this.third.push(bannerDiscoverArray[i]);
                }
            }
        }
    } else {
        console.error(error);
    }
    },
};
</script>
