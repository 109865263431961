<template>
  <div class="platform-section">
    <div class="container container-xl">
      <div class="card-title-box">
        <div class="title">
          <h3>{{ $t("Platforms") }}</h3>
        </div>
      </div>
      <div class="row" v-if="!mobile">
        <div class="col-sm-6 col-md-4" v-for="(platform, index) in platforms" :key="index">
          <a :href="`/${settings.language}/products/platform/${platform.slug}`" rel="preload" >
            <div :class="`block block__${index + 1}`">
              <div class="block__content">
                <div class="block__text">{{ platform.name }}</div>
              </div>
              <div class="block__ellipse"></div>
              <div class="block__img">
                <img rel="preload" as="image" class="img-hover" :src="`/img/pages/site/${platform.imageHover}`" alt=""/>
                <img rel="preload" as="image" class="img-block" :src="`/img/pages/site/${platform.image}`" alt=""/>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row" v-else>
        <Swiper
        class="platform-slider"
        :slides-per-view="'auto'"
        :loop="true"
        :centeredSlides="true"
        :initialSlide="1"
      >
        <SwiperSlide v-for="(platform, index) in platforms" :key="index">
          <a :href="`/${settings.language}/products/platform/${platform.slug}`" rel="preload" >
            <div :class="`block block__${index + 1}`">
              <div class="block__content">
                <div class="block__text">{{ platform.name }}</div>
              </div>
              <div class="block__ellipse"></div>
              <div class="block__img">
                <img rel="preload" as="image" class="img-hover" :src="`/img/pages/site/${platform.imageHover}`" alt=""/>
                <img rel="preload" as="image" class="img-block" :src="`/img/pages/site/${platform.image}`" alt=""/>
              </div>
            </div>
          </a>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      platforms: [
        { name: "Playstation 5", slug: "ps5", image: "ps5.webp", imageHover: "ps5-hover.webp" },
        { name: "Playstation 4", slug: "ps4", image: "ps4.webp", imageHover: "ps4-hover.webp" },
        { name: "PC", slug: "pc", image: "pc.webp", imageHover: "pc-hover.webp" },
        { name: "Xbox series X", slug: "xbox-series", image: "xboxX.webp", imageHover: "xboxX-hover.webp" },
        { name: "Xbox One", slug: "xbox-one", image: "xboxOne.webp", imageHover: "xboxOne-hover.webp" },
        { name: "Nintendo Switch", slug: "switch", image: "nintendo.webp", imageHover: "nintendo-hover.webp" },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>